const toJSON = (json) => {
	try {
		return JSON.parse(json);
	} catch (error) {
		return null;
	}
};

export const getAccessToken = () => localStorage.getItem('accessToken');

export const setAccessToken = (token) => localStorage.setItem('accessToken', token);
export const setAllAccounts = (accounts) => toJSON(localStorage.setItem('allAccounts', JSON.stringify(accounts)));
export const getAllAccounts = () => toJSON(localStorage.getItem('allAccounts'));

export const setAccountsAccessToken = (token) => localStorage.setItem('AccountaccessToken', token);
export const getAccountsAccessToken = () => localStorage.getItem('AccountaccessToken');

export const getIsFirstLogin = () => localStorage.getItem('isFirstLogin') === 'true';

/**
 * @param {boolean} value
 * @returns {void}
 */
export const setIsFirstLogin = (value) => localStorage.setItem('isFirstLogin', JSON.stringify(value));

export const clearSession = () => {
	localStorage.removeItem('accessToken');
	sessionStorage.clear();
};
