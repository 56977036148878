import { Tag } from 'antd';
import React from 'react';

const DEPARTMENT_TYPE = {
	DESIGN: {
		label: 'Design',
		color: 'purple',
	},
	PRODUCT: {
		label: 'Product',
		color: 'blue',
	},
	MARKETING: {
		label: 'Marketing',
		color: 'geekblue',
	},
	SOFTWARE: {
		label: 'Software Engineering',
		color: 'green',
	},
	FINANCE: {
		label: 'Finance',
		color: 'geekblue',
	},
	OPERATIONS: {
		label: 'Operations',
		color: 'pink',
	},
	CUSTOMER_SUCCESS: {
		label: 'Customer Success',
		color: 'blue',
	},
};

export default function DepartmentTag({ department }) {
	return (
		// DEPARTMENT_TYPE[department] && (
		// 	<Tag style={{ borderRadius: 50, margin: 0 }} color={DEPARTMENT_TYPE[department]?.color || 'blue'}>
		// 		{DEPARTMENT_TYPE[department]?.label || department}
		// 	</Tag>
		// )

		<Tag style={{ borderRadius: 50, margin: 0 }} color="purple">
			{department}
		</Tag>
	);
}
