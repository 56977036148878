import { Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { PATH_NAME } from '../Routes/Pathname';
import { useNavigate } from 'react-router-dom';
import { AppColors } from '../Utils/helper/AppColors';
import TextLogo from '../Components/TextLogo';
import Navlist from './Navlist';
import { LuLogOut } from 'react-icons/lu';
import useResponsive from '../Utils/hooks/useResponsive';
import { useSelector } from 'react-redux';
import UserThumbnail from '../Components/UserThumbnail';

export default function SideBarNav() {
	const navigate = useNavigate();
	const { small } = useResponsive();
	const navBarExpanded = useSelector((state) => state.app.navBarExpanded);
	const user = useSelector((state) => state.app.user);

	const handleSignOut = async () => {
		localStorage.clear();
		window.location.reload();
		navigate(PATH_NAME.LOGIN);
	};

	return (
		<Stack
			p={1.5}
			gap={1.5}
			top={0}
			width={240}
			height="100vh"
			bgcolor={AppColors.WHITE}
			position={small ? 'absolute' : 'relative'}
			borderRight="1px solid"
			borderColor={AppColors.BORDER_GREY}
			zIndex={5}
			sx={{ transform: `translateX(${!small ? '0%' : navBarExpanded ? '0' : '-100%'})`, transition: 'all .2s' }}
		>
			<TextLogo />
			<Divider />
			<Navlist />

			<Stack
				pt={2}
				maxWidth="100%"
				direction="row"
				alignItems="center"
				borderTop="1px solid"
				borderColor={AppColors.BORDER_GREY}
				mt="auto"
			>
				<UserThumbnail size={30} hideName user={user} />
				<Stack
					ml={1}
					sx={{
						'& .MuiTypography-root': {
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							maxWidth: 148,
						},
					}}
				>
					<Typography fontSize={12} fontWeight={600} color={AppColors.TEXT_SECONDARY}>
						{`${user?.firstName || ''} ${user?.lastName}`}
					</Typography>
					<Typography fontSize={12} fontWeight={400} color={AppColors.TEXT_TERTIARY}>
						{user?.email}
					</Typography>
				</Stack>
				<LuLogOut
					onClick={handleSignOut}
					color={AppColors.TEXT_TERTIARY}
					size={18}
					cursor="pointer"
					style={{ marginLeft: 'auto' }}
				/>
			</Stack>
		</Stack>
	);
}
