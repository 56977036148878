import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import TextLogo from '../../../Components/TextLogo';
import { AppColors } from '../../../Utils/helper/AppColors';
import ReviewCards from './ReviewCards';
import useResponsive from '../../../Utils/hooks/useResponsive';

export default function LoginLayout({ children, heading, subHeading }) {
	const { small } = useResponsive();
	return (
		<Grid container p={2} direction="row" height="100vh">
			<Grid item xs={small ? 12 : 6} height="100%" alignItems="center" justifyContent="center" display="flex">
				<Stack width={small ? '86%' : '54%'} maxWidth={500}>
					<TextLogo mb={1.5} align="center" />
					<Stack gap={1}>
						<Typography textAlign="center" fontSize={30} fontWeight={500} color={AppColors.TEXT_PRIMARY}>
							{heading}
						</Typography>
						<Typography textAlign="center" fontSize={16} fontWeight={400} color={AppColors.TEXT_TERTIARY}>
							{subHeading}
						</Typography>
					</Stack>

					<Stack mt={3} gap={2}>
						{children}
					</Stack>
				</Stack>
			</Grid>
			{!small && (
				<Grid height="100%" item xs={6}>
					<ReviewCards />
				</Grid>
			)}
		</Grid>
	);
}
