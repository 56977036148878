import { Modal, Stack, Typography } from '@mui/material';
import React from 'react';
import { AppColors } from '../../../Utils/helper/AppColors';
import IconContainer from '../../../Components/IconContainer';
import { RiFlagLine } from 'react-icons/ri';
import { CloseIcon } from '../../../Components/Icons/Icons';
import CreateEditCampaign from './CreateEditCampaign';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: AppColors.WHITE,
	borderRadius: 3,
};

export default function CreateCampaignModal({ open, setOpen, fetch }) {
	return (
		<Modal type="blur" open={open}>
			<Stack width="70vw" minHeight={200} sx={style}>
				<Stack
					p={2.5}
					borderBottom="1px solid"
					borderColor={AppColors.BORDER_GREY}
					direction="row"
					alignItems="flex-start"
					justifyContent="space-between"
				>
					<Stack direction="row" alignItems="center" gap={2}>
						<IconContainer icon={<RiFlagLine />} />
						<Stack>
							<Typography variant="body1">Create campaign</Typography>
							<Typography variant="subtitle2">Fill in the basic campaign details</Typography>
						</Stack>
					</Stack>

					<CloseIcon onClick={() => setOpen(false)} color="#98A2B3" size={24} />
				</Stack>
				<CreateEditCampaign setOpen={setOpen} fetch={fetch} />
			</Stack>
		</Modal>
	);
}
