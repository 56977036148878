import { Avatar, Stack, Typography } from '@mui/material';
import React from 'react';
import { AppColors } from '../Utils/helper/AppColors';

export default function UserThumbnail({ user, hideName, size, vertical }) {
	const getInitials = function () {
		let firstName = user?.firstName;
		let lastName = user?.lastName;
		let initials = !firstName && !lastName ? 'na' : (firstName?.[0] || '') + (lastName?.[0] || '');
		return initials.toUpperCase();
	};

	const getUsername = function () {
		let username = user?.anonymous ? '****' : (user?.firstName || user?.name)?.split(' ')?.[0]?.toLowerCase();
		return `@${username || 'na'}`;
	};

	return (
		<Stack direction={vertical ? 'column' : 'row'} alignItems="center" gap={1.5}>
			{user?.image ? (
				<Avatar
					sx={{ height: size || 34, width: size || 34 }}
					src="https://img.freepik.com/free-photo/portrait-young-woman-with-natural-make-up_23-2149084942.jpg"
				/>
			) : (
				<Stack
					flexShrink={0}
					height={size || 34}
					width={size || 34}
					borderRadius={50}
					bgcolor={AppColors.BORDER_GREY}
					border="1px solid"
					borderColor="#dee1e3"
					alignItems="center"
					justifyContent="center"
				>
					<Typography variant="subtitle2">{getInitials()}</Typography>
				</Stack>
			)}
			{!hideName && (
				<Typography variant="subtitle1">
					{user?.anonymous ? 'Anonymous' : `${user?.firstName || ''} ${user?.lastName || ''}`}
				</Typography>
			)}
		</Stack>
	);
}
