import React from 'react';
import { Grid, InputLabel, TextField } from '@mui/material';
import { AppColors } from '../../../../Utils/helper/AppColors';
import StepperHeading from '../StepperHeading';

const MAX_CHAR = 275;

export default function BasicDetails({ details, setDetails }) {
	const handleInputChange = (e) => {
		setDetails({
			...details,
			basicDetails: {
				...details?.basicDetails,
				[e.target.name]: e.target?.value,
			},
		});
	};

	return (
		<>
			<StepperHeading title="Basic Details" subTitle="Fill in the basic campaign details" />
			<Grid container spacing={2}>
				<Grid item xs={4}>
					<InputLabel>Name</InputLabel>
				</Grid>
				<Grid item xs={8}>
					<TextField name="name" value={details?.basicDetails?.name} onChange={handleInputChange} fullWidth size="small" />
				</Grid>
				<Grid item xs={4}>
					<InputLabel>Description</InputLabel>
					<InputLabel sx={{ fontWeight: 400, color: AppColors.TEXT_TERTIARY, fontSize: 12 }}>
						Write a short introduction.
					</InputLabel>
				</Grid>
				<Grid item xs={8}>
					<TextField
						name="description"
						inputProps={{ maxLength: MAX_CHAR }}
						value={details?.basicDetails?.description}
						onChange={handleInputChange}
						helperText={`${MAX_CHAR - (details?.basicDetails?.description?.length || 0)} characters left`}
						fullWidth
						multiline
						rows="5"
						size="small"
					/>
				</Grid>
			</Grid>
		</>
	);
}
