import { Card, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MonthChange from './MonthChange';
import LineChart from '../../../Components/Charts/LineChart';
import { AppColors } from '../../../Utils/helper/AppColors';
import _ from 'lodash';

export default function LineGraphCard({ value, title, additionalFilters, values }) {
	const [lineData, setLineData] = useState([]);
	const [labels, setLabels] = useState([]);

	useEffect(() => {
		if (additionalFilters.length > 0 && _.isArray(values)) {
			setLineData(
				values?.map((i) => {
					return {
						label: i?.name,
						data: _.isArray(i?.value) ? i?.value?.map((j) => j?.value) : [],
						backgroundColor: additionalFilters?.find((j) => j?.code == i?.name)?.barColor,
						borderColor: additionalFilters?.find((j) => j?.code == i?.name)?.color,
						borderWidth: 2,
						pointRadius: 4,
					};
				}),
			);
		} else {
			setLineData([
				{
					data: values?.map((i) => i?.value),
					borderColor: AppColors.PRIMARY_BLUE,
					borderWidth: 2,
					pointRadius: 4,
				},
			]);
		}
	}, [additionalFilters, values]);

	useEffect(() => {
		if (additionalFilters?.length > 0 && _.isArray(values[0]?.value)) {
			setLabels(values[0]?.value?.map((i) => i?.name));
		} else {
			setLabels(values?.map((i) => i?.name));
		}
	}, [additionalFilters, values]);

	const chartData = {
		labels,
		datasets: lineData,
	};

	return (
		<Card>
			<Stack mb={2}>
				<Typography variant="body1">{title}</Typography>
			</Stack>
			<Stack mb={2} direction="row" alignItems="center" gap={2}>
				<Typography variant="subtitle1" fontSize={36} fontWeight={600}>
					{value || 'NA'}%
				</Typography>
				{/* <MonthChange title="conversation" change={10} effect="positive" /> */}
			</Stack>

			<LineChart yGrid grid data={chartData} width="100%" />
		</Card>
	);
}
