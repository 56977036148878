import { Divider, Stack, Typography } from '@mui/material';
import React from 'react';

export default function StepperHeading({ title, subTitle, renderRight }) {
	return (
		<Stack gap={2} my={2.5}>
			<Divider />
			<Stack direction="row" alignItems="center" justifyContent="space-between">
				<Stack>
					<Typography variant="body1">{title}</Typography>
					<Typography variant="subtitle2">{subTitle}</Typography>
				</Stack>
				{renderRight}
			</Stack>
			<Divider />
		</Stack>
	);
}
