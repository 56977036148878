import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Stack } from '@mui/material';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function BarChart({ data, height, width, grid = false, barPercentage }) {
	const graphOptions = {
		barPercentage,
		responsive: true,
		plugins: {
			legend: {
				display: false,
				position: 'top',
			},
		},
		scales: {
			x: {
				ticks: {
					display: grid,
				},
				grid: {
					drawBorder: grid,
					display: false,
					color: '#edf2f5',
				},
				border: {
					display: false,
				},
			},
			y: {
				ticks: {
					display: grid,
				},
				grid: {
					drawBorder: false,
					display: grid,
					color: '#edf2f5',
				},
				border: {
					display: false,
				},
			},
		},
	};
	return (
		<Stack maxWidth={width} width={width} height={height}>
			<Bar options={graphOptions} data={data} />
		</Stack>
	);
}
