import {
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageHeading from '../../Components/PageHeading';
import { AppColors } from '../../Utils/helper/AppColors';
import MentionProgress from './Components/MentionProgress';
import { DotIcon } from '../../Components/Icons/Icons';
import CultService from '../../Services/CultService';
import toast from 'react-hot-toast';
import { HelperTexts } from '../../Utils/helper/HelperTexts';
import PageLoader from '../../Components/PageLoader';
import { useSelector } from 'react-redux';

function RenderItemsList(props) {
	const { value, index, items } = props;
	return (
		value === index && (
			<TableContainer key={index} sx={{ height: 'calc(100vh - 190px)', mt: 2 }}>
				<Table stickyHeader>
					<TableHead sx={{ '& .MuiTableCell-root': { fontSize: 12, color: AppColors.TEXT_TERTIARY, fontWeight: 500 } }}>
						<TableCell>Clusters</TableCell>
						<TableCell>Mentions</TableCell>
						<TableCell>Tentative Solution</TableCell>
					</TableHead>

					<TableBody>
						{items?.map((i, index) => (
							<TableRow key={index}>
								<TableCell>
									<Typography variant="subtitle1">{i?.clusterName}</Typography>
									<Typography variant="subtitle2">{i?.name}</Typography>
								</TableCell>
								<TableCell>
									<MentionProgress value={i.percentage} />
								</TableCell>
								<TableCell>{i?.solution}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		)
	);
}

export default function ReportingActionable() {
	const insights = [
		{
			label: 'Employee Engagement',
			code: 'employeeEngagement',
			key: 'issues',
		},
		{
			label: 'Productivity drivers',
			code: 'productivityDrivers',
			key: 'drivers',
		},
		{
			label: 'Risk',
			code: 'risk',
			key: 'risks',
		},
		{
			label: 'Employee Wellness',
			code: 'employeeWellness',
			key: 'wellnessIssues',
		},
		// {
		// 	label: 'Cultural Alignment',
		// 	code: 'culturalAlignment',
		// 	key: 'alignmentIssues',
		// },
	];

	const [loading, setLoading] = useState(false);
	const [value, setValue] = React.useState(0);
	const company = useSelector((state) => state?.app?.company);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const [details, setDetails] = useState(null);
	const [list, setList] = useState([]);

	const getDetails = async () => {
		setLoading(true);
		try {
			let res = await CultService.getActionalbleInsigts({ params: { companyId: company?._id } });
			setDetails(res?.data?.data);
		} catch (err) {
			toast.error(HelperTexts?.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (company) getDetails();
	}, [company]);

	useEffect(() => {
		if (details) {
			setList(
				details[insights[value]?.code]?.flatMap((i) =>
					i[insights[value]?.key]?.map((j) => ({ ...j, clusterName: i?.clusterName })),
				),
			);
		}
	}, [details, value]);

	return (
		<Stack>
			{loading && <PageLoader />}
			<PageHeading title="Actionable Insights" subTitle="Insights into profound employee dynamics" />

			<Stack mt={5}>
				<ToggleButtonGroup size="small" color="primary" value={value} exclusive onChange={handleChange}>
					{insights.map((i, index) => (
						<ToggleButton
							key={index}
							sx={{ textTransform: 'none', px: 2, borderRadius: '8px', alignItems: 'center' }}
							value={index}
						>
							<Typography display="flex" alignItems="center" gap={1} variant="subtitle2" fontSize={13} fontWeight={500}>
								{value == index && <DotIcon color={AppColors.TEXT_SUCCESS} size={8} />} {i?.label}
							</Typography>
						</ToggleButton>
					))}
				</ToggleButtonGroup>

				{insights.map((i, index) => (
					<RenderItemsList value={value} index={index} items={list} />
				))}
			</Stack>
		</Stack>
	);
}
