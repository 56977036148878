import './App.css';
import React, { useEffect } from 'react';
import AppRoutes from './Routes/AppRoutes';
import { ThemeProvider } from '@mui/material';
import { theme } from './Utils/theme';
import { ConfigProvider } from 'antd';
import { antTheme } from './Utils/antTheme';
import { AppColors } from './Utils/helper/AppColors';
import { Toaster } from 'react-hot-toast';
import CultService from './Services/CultService';
import { setCompany, setUser } from './store/reducers/appActions';
import { useDispatch } from 'react-redux';

function App() {
	const dispatch = useDispatch();
	const getUserDetails = async () => {
		let user = await CultService.getUserById({ params: { id: localStorage.getItem('userId') } });
		if (user.status == 200) {
			localStorage.setItem('user', JSON.stringify(user?.data?.data));
			dispatch(setUser(user?.data?.data));
			dispatch(setCompany(user?.data?.data?.company));
		}
	};

	useEffect(() => {
		const userId = localStorage.getItem('userId');
		if (userId) {
			getUserDetails();
		}
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<Toaster toastOptions={{ style: { minWidth: 'fit-content' } }} />
			<ConfigProvider
				theme={{
					token: {
						colorPrimary: AppColors.PRIMARY_LIGHT,
						colorPrimaryHover: AppColors.PRIMARY_DARK,
					},
					components: {
						Button: {
							controlHeight: 40,
							borderRadius: 8,
							fontWeight: 500,
							defaultBorderColor: AppColors.BORDER_PRIMARY,
							defaultHoverBorderColor: AppColors.BLACK,
							defaultHoverColor: AppColors.BLACK,
							defaultHoverBg: '#f9f9f9',
						},
						Tag: {
							colorBgContainer: 'red',
							borderRadius: '50px !important',
						},
						DatePicker: {
							colorBorder: AppColors.BORDER_PRIMARY,
							borderRadius: 8,
							controlHeight: 40,
						},
						Select: {
							colorBorder: AppColors.BORDER_PRIMARY,
							borderRadius: 8,
							controlHeight: 40,
							fontSize: 16,
							colorTextPlaceholder: '#a2a2a2',
						},
					},
				}}
			>
				<AppRoutes />
			</ConfigProvider>
		</ThemeProvider>
	);
}

export default App;
