import { Button, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { AppColors } from '../../Utils/helper/AppColors';
import { LuArrowLeft } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import { PATH_NAME } from '../../Routes/Pathname';
import authBackground from '../../Assets/authBackground.svg';
import LabelledInput from '../../Components/LabelledInput';
import { GoKey } from 'react-icons/go';
import useResponsive from '../../Utils/hooks/useResponsive';

export default function ForgetPassword() {
	const navigate = useNavigate();
	const { small } = useResponsive();
	return (
		<Stack
			sx={{ background: `url(${authBackground})`, backgroundPosition: 'center' }}
			width={small ? '80%' : 600}
			mx="auto"
			alignItems="center"
			gap={2}
			pt={8}
		>
			<Stack
				bgcolor={AppColors.WHITE}
				width={56}
				height={56}
				border="1px solid #EAECF0"
				borderRadius="12px"
				alignItems="center"
				justifyContent="center"
			>
				<GoKey color="#344054" size={26} />
			</Stack>

			<Typography textAlign="center" fontSize={30} fontWeight={600} color={AppColors.TEXT_PRIMARY}>
				Forgot password?
			</Typography>
			<Typography textAlign="center" fontSize={16} fontWeight={400} color={AppColors.TEXT_TERTIARY}>
				No worries, we’ll send you reset instructions.
			</Typography>

			<Stack mx="auto">
				<LabelledInput label="Email">
					<TextField sx={{ width: 360 }} size="small" placeholder="Enter your email" />
				</LabelledInput>
			</Stack>

			<Button variant="contained" sx={{ width: 360 }}>
				Reset Password
			</Button>

			<Button variant="text" color="secondary" onClick={() => navigate(PATH_NAME.LOGIN)} startIcon={<LuArrowLeft />}>
				Back to Login
			</Button>
		</Stack>
	);
}
