import { Button, Grid, Stack } from '@mui/material';
import React from 'react';
import PageHeading from '../../Components/PageHeading';
import { AddIcon } from '../../Components/Icons/Icons';
import { AppColors } from '../../Utils/helper/AppColors';
import LineCard from './Components/LineCard';
import BarCard from './Components/BarCard';

export default function LeadingTurnover() {
	return (
		<Stack>
			<PageHeading
				title="Leading Turnover Reasons"
				subTitle="Representation of leading turnover reasons through graphical trend"
				renderRight={
					<Button variant="contained" startIcon={<AddIcon color={AppColors.WHITE} />}>
						Create Contact
					</Button>
				}
			/>

			<Grid container mt={0} columnSpacing={3} rowSpacing={5}>
				<Grid item xs={4}>
					<LineCard title="As per the current data, 70% more employees have been increased who feel undervalued." />
				</Grid>
				<Grid item xs={4}>
					<LineCard title="This trend shows that, 65% more employees have experienced lack of diversity in workplace." />
				</Grid>
				<Grid item xs={4}>
					<LineCard title="In this alarming trend, 56% more employees felt overworked." />
				</Grid>

				<Grid item xs={4}>
					<BarCard title="Undervaluation" />
				</Grid>
				<Grid item xs={4}>
					<BarCard title="Lack of diversity" />
				</Grid>
				<Grid item xs={4}>
					<BarCard title="Overworked" />
				</Grid>
			</Grid>
		</Stack>
	);
}
