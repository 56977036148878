import { Divider, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import PageHeading from '../../Components/PageHeading';
import SearchBar from '../../Components/SearchBar';
import IntegrationAppItem from './IntegrationAppItem';
import { filterBySearch } from '../../Utils/helper/commonUtils';

export default function Integration() {
	const [search, setSearch] = useState('');
	const [apps, setApps] = useState([
		{
			type: 'HR Management',
			items: [
				{
					name: 'Zoho',
					description:
						'Zoho People is your complete online HR solution. Automate and simplify your HR operations with our easy-to-use and customizable HR software',
				},
				{
					name: 'Workday',
					description:
						'Workday Enterprise Management Cloud gives organizations of all sizes the power to adapt through finance, HR, planning, spend management, and analytics description',
				},
				{
					name: 'Oracle HCM',
					description:
						'Pay employees anywhere across the globe. Simplify how you pay your workforce with a highly configurable solution that is fully unified with Oracle Fusion Cloud ...',
				},
				{
					name: 'SAP',
					description:
						'HRMS stands for human resource management system. Companies use an HRMS to digitalize, automate, and centralize HR activities and processes throughout the ...',
				},
			],
		},
		{
			type: 'Others',
			items: [
				{
					name: 'Gmail',
					description: 'Link with Gmail, so CultureOS can remind about filling in the forms',
				},
				{
					name: 'MS Teams',
					description: 'Link with MS teams for direct chat interaction with CultureOS',
				},
				{
					name: 'Slack',
					description: 'Send notifications to channels and create projects from messages.',
				},
			],
		},
	]);
	return (
		<Stack pb={5}>
			<PageHeading title="Integration" subTitle="Connect with other apps team is using on daily basis" />

			<Stack mt={3} direction="row" justifyContent="space-between" alignItems="center">
				<Stack>
					<Typography variant="body1">Connected Apps</Typography>
					<Typography variant="subtitle2">Supercharge your workflow and connect the tool you use every day.</Typography>
				</Stack>

				<SearchBar search={search} setSearch={setSearch} width={360} />
			</Stack>

			{apps.map((app) => (
				<>
					<Divider sx={{ my: 3 }} />
					<Stack gap={4}>
						<Typography variant="subtitle2" fontWeight={600}>
							{app.type}
						</Typography>
						<Stack gap={2}>
							{filterBySearch(app.items, search, ['name'])?.map((item, index) => (
								<>
									{index !== 0 && <Divider />}
									<IntegrationAppItem key={index} item={item} />
								</>
							))}
						</Stack>
					</Stack>
				</>
			))}
		</Stack>
	);
}
