export const AppColors = {
	WHITE: '#FFF',
	PRIMARY_DARK: '#5B21B6',
	PRIMARY_LIGHT: '#7F56D9',
	TEXT_PRIMARY: '#101828',
	TEXT_SECONDARY: '#344054',
	TEXT_TERTIARY: '#475467',
	BORDER_GREY: '#EAECF0',
	BORDER_PRIMARY: '#D0D5DD',
	BACKGROUND_ACTIVE: '#F9FAFB',
	BACKGROUND_PRIMARY: '#F4EBFF',
	TEXT_SUCCESS: '#17B26A',
	TEXT_ERROR: '#D92D20',
	DARK_GREEN: '#079455',
	CHART_GREEN: '#9BDFC4',
	CHART_BLUE: '#62B2FD',
	CHART_PRIMARY_DARK: '#6941C6',
	CHART_PRIMARY_LIGHT: '#9E77ED',
	TEXT_RED: '#EF4444',
	DARK_GREY: '#667085',
	BLACK: '#000000',
	PRIMARY_BLUE: '#0A06F4',
};
