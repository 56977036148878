import { IoMdClose } from 'react-icons/io';
import { AppColors } from '../../Utils/helper/AppColors';
import { FiEdit2, FiPlus, FiUploadCloud } from 'react-icons/fi';
import { MdInfoOutline, MdOutlineDelete } from 'react-icons/md';
import { FaCircle } from 'react-icons/fa';
import { GoRocket } from 'react-icons/go';
import {IoIosRocket} from "react-icons/io"

export const AddIcon = ({ size, color, onClick }) => {
	return <FiPlus size={size || 20} color={color || AppColors.TEXT_TERTIARY} onClick={onClick} />;
};

export const UploadIcon = ({ size, color, onClick }) => {
	return <FiUploadCloud size={size || 20} color={color || AppColors.TEXT_TERTIARY} onClick={onClick} />;
};

export const CloseIcon = ({ size, color, onClick }) => {
	return <IoMdClose cursor="pointer" size={size || 20} color={color || AppColors.TEXT_TERTIARY} onClick={onClick} />;
};

export const EditIcon = ({ size, color, onClick }) => {
	return <FiEdit2 cursor="pointer" size={size || 18} color={color || AppColors.TEXT_TERTIARY} onClick={onClick} />;
};
export const DeleteIcon = ({ size, color, onClick }) => {
	return <MdOutlineDelete cursor="pointer" size={size || 22} color={color || AppColors.TEXT_TERTIARY} onClick={onClick} />;
};

export const InfoIcon = ({ size, color, onClick }) => {
	return <MdInfoOutline cursor="pointer" size={size || 16} color={color || AppColors.DARK_GREY} onClick={onClick} />;
};

export const DotIcon = ({ size, color }) => {
	return <FaCircle size={size || 10} color={color} />;
};

export const RocketIcon = ({ size, color }) => {
	return <IoIosRocket size={size || 20} color={color} />;
};
