import { Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageHeading from '../../Components/PageHeading';
import { RiDownloadCloud2Line } from 'react-icons/ri';
import { AppColors } from '../../Utils/helper/AppColors';
import DotChip from '../../Components/DotChip';
import CommentThreadColumn from './Components/CommentThreadColumn';
import DepartmentTag from '../../Components/DepartmentTag';
import CultService from '../../Services/CultService';
import toast from 'react-hot-toast';
import { HelperTexts } from '../../Utils/helper/HelperTexts';
import PageLoader from '../../Components/PageLoader';
import { useSelector } from 'react-redux';
import UserThumbnail from '../../Components/UserThumbnail';

export default function IssuesRaised() {
	const [loading, setLoading] = useState(false);
	const [issues, setIssues] = useState([]);
	const company = useSelector((state) => state?.app?.company);

	const getDetails = async () => {
		setLoading(true);
		try {
			let res = await CultService.getCallIssues({ params: { companyId: company?._id } });
			setIssues(res?.data?.data?.issues);
		} catch (err) {
			toast.error(HelperTexts?.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (company) getDetails();
	}, [company]);

	return (
		<Stack>
			{loading && <PageLoader />}
			<PageHeading
				// renderRight={
				// 	<Button startIcon={<RiDownloadCloud2Line />} color="secondary" variant="outlined">
				// 		Export
				// 	</Button>
				// }
				title="Issues raised"
				subTitle="Track, manage and resolve employees raised issues"
			/>

			<TableContainer sx={{ height: 'calc(100vh - 140px)', mt: 5 }}>
				<Table stickyHeader>
					<TableHead sx={{ '& .MuiTableCell-root': { fontSize: 12, color: AppColors.TEXT_TERTIARY, fontWeight: 500 } }}>
						<TableCell width={200}>Name</TableCell>
						<TableCell>Issue</TableCell>
						<TableCell>Category</TableCell>
						<TableCell width={200}>Department</TableCell>
						{/* <TableCell /> */}
					</TableHead>
					<TableBody>
						{issues?.map((c, index) => (
							<TableRow key={index}>
								<TableCell>
									<UserThumbnail user={{ firstName: c.name }} />
								</TableCell>
								<TableCell>
									<Typography sx={{ textTransform: 'capitalize' }} variant="subtitle2">
										{c?.callIssues?.issues?.join(', ')}
									</Typography>
								</TableCell>
								<TableCell>
									{c?.callIssues?.category && <DotChip color={AppColors.TEXT_SUCCESS} label={c?.callIssues?.category} />}
								</TableCell>
								<TableCell>
									<DepartmentTag department={c?.department} />
								</TableCell>
								{/* <TableCell>
									<CommentThreadColumn />
								</TableCell> */}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Stack>
	);
}
