import { Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageHeading from '../../Components/PageHeading';
import { RiDownloadCloud2Line } from 'react-icons/ri';
import { AppColors } from '../../Utils/helper/AppColors';
import moment from 'moment/moment';
import { MdOutlinePlayCircleOutline } from 'react-icons/md';
import PlayingModal from './Components/PlayingModal';
import CultService from '../../Services/CultService';
import toast from 'react-hot-toast';
import { HelperTexts } from '../../Utils/helper/HelperTexts';
import { useSelector } from 'react-redux';
import UserThumbnail from '../../Components/UserThumbnail';
import PageLoader from '../../Components/PageLoader';

export default function CallRecordings() {
	const [selected, setSelected] = useState(null);
	const company = useSelector((state) => state?.app?.company);
	const [loading, setLoading] = useState(false);

	const [recordings, setRecordings] = useState([]);

	const getRecordings = async () => {
		setLoading(true);
		try {
			let res = await CultService.getCallRecordings({ params: { companyId: company?._id } });
			setRecordings(res?.data?.data?.issues);
		} catch (err) {
			toast.error(HelperTexts?.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (company) {
			getRecordings();
		}
	}, [company]);

	return (
		<Stack>
			{loading && <PageLoader />}
			<PageHeading
				// renderRight={
				// 	<Button startIcon={<RiDownloadCloud2Line />} color="secondary" variant="outlined">
				// 		Download Recordings
				// 	</Button>
				// }
				title="Call Recordings"
				subTitle="Listen and manage calls"
			/>

			<TableContainer sx={{ height: 'calc(100vh - 136px)', mt: 5 }}>
				<Table stickyHeader>
					<TableHead sx={{ '& .MuiTableCell-root': { fontSize: 12, color: AppColors.TEXT_TERTIARY, fontWeight: 500 } }}>
						<TableCell>Call Recordings</TableCell>
						<TableCell>Employee</TableCell>
						<TableCell width="50%">Issue</TableCell>
						<TableCell>Date</TableCell>
					</TableHead>
					<TableBody>
						{recordings.map((c, index) => (
							<TableRow>
								<TableCell>
									<Stack direction="row" alignItems="center" gap={1}>
										<MdOutlinePlayCircleOutline
											cursor="pointer"
											onClick={() => setSelected(c)}
											color={AppColors.PRIMARY_DARK}
											size={32}
										/>
										<Stack>
											<Typography variant="subtitle1">Recording {index + 1}</Typography>
											{/* <Typography variant="subtitle2">02:30</Typography> */}
										</Stack>
									</Stack>
								</TableCell>

								<TableCell>
									<UserThumbnail user={c?.userId} />
								</TableCell>
								<TableCell>
									<Typography variant="subtitle2">{c.callIssues?.issues?.join(', ')}</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="subtitle2" fontSize={12}>
										{moment(c?.call?.startedAt).format('MMM DD, YY')}
									</Typography>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<PlayingModal open={Boolean(selected)} onClose={() => setSelected(null)} selected={selected} />
		</Stack>
	);
}
