import { Divider, Modal, Stack, Typography } from '@mui/material';
import React from 'react';
import moment from 'moment';
import 'react-h5-audio-player/lib/styles.css';
import ReactAudioPlayer from 'react-audio-player';
import { RiDeleteBin6Line, RiSkipLeftLine, RiSkipRightLine } from 'react-icons/ri';
import { FiDownload } from 'react-icons/fi';
import { AppColors } from '../../../Utils/helper/AppColors';
import UserThumbnail from '../../../Components/UserThumbnail';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	outline: 'none',
	bgcolor: AppColors.WHITE,
	borderRadius: 3,
};

export default function PlayingModal({ open, onClose, selected }) {
	const Icon = ({ bgcolor, icon }) => (
		<Stack
			sx={{ cursor: 'pointer', alignItems: 'center', justifyContent: 'center', svg: { fontSize: 20 } }}
			height={40}
			width={40}
			borderRadius={50}
			bgcolor={bgcolor}
		>
			{icon}
		</Stack>
	);

	return (
		<Modal open={open} onClose={onClose}>
			<Stack p={3} gap={1} sx={style}>
				<UserThumbnail size={100} vertical user={selected?.userId} />

				<Divider />

				<Stack direction="row" justifyContent="center" alignItems="center" gap={3}>
					<Typography variant="subtitle2">{moment(selected?.call?.startedAt).format('MMM DD, YY')}</Typography>
					<Typography variant="subtitle2">{moment(selected?.call?.startedAt).format('hh:mm A')}</Typography>
				</Stack>

				<ReactAudioPlayer src={selected?.call?.artifact?.recordingUrl} autoPlay controls />

				{/* <Stack mt={2} direction="row" alignItems="center" justifyContent="space-around">
					<Icon bgcolor="#F4EBFF" icon={<RiSkipLeftLine color="#667085" />} />
					<Icon bgcolor="#ECFDF3" icon={<FiDownload color="#067647" />} />
					<Icon bgcolor="#FEF3F2" icon={<RiDeleteBin6Line color="#B42318" />} />
					<Icon bgcolor="#F4EBFF" icon={<RiSkipRightLine color="#667085" />} />
				</Stack> */}
			</Stack>
		</Modal>
	);
}
