import { Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { CloseIcon } from './Icons/Icons';

export default function FilterSelectionContainer({ onClose, children, handleApplyChanges, title = 'dashboard' }) {
	return (
		<Stack height="100%" p={2} width={400} gap={2}>
			<Stack direction="row" alignItems="flex-start" justifyContent="space-between">
				<Stack gap={0.5}>
					<Typography variant="body1">Filters</Typography>
					<Typography variant="subtitle2">Apply filters to {title}</Typography>
				</Stack>

				<IconButton onClick={onClose} size="small">
					<CloseIcon />
				</IconButton>
			</Stack>

			<Divider />

			{children}

			<Divider sx={{ mt: 'auto' }} />
			<Stack direction="row" alignItems="center" gap={2} justifyContent="center" pb={2}>
				<Button onClick={onClose} variant="outlined" color="secondary">
					Cancel
				</Button>
				<Button onClick={handleApplyChanges} variant="contained">
					Apply
				</Button>
			</Stack>
		</Stack>
	);
}
