import { InputAdornment, Stack, TextField } from '@mui/material';
import React from 'react';
import { FiSearch } from 'react-icons/fi';

export default function SearchBar(props) {
	const { placeholder = 'Search', width = '100%', search, setSearch } = props;
	return (
		<TextField
			sx={{ width }}
			fullWidth
			size="small"
			placeholder={placeholder}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<FiSearch color="#667085" size={18} />
					</InputAdornment>
				),
			}}
			value={search}
			onChange={(e) => setSearch(e.target.value)}
			{...props}
		/>
	);
}
