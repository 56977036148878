import React from 'react';
import StepperHeading from '../StepperHeading';
import { Grid, InputLabel } from '@mui/material';
import { Select } from 'antd';

export default function CampaignConversationType({ details, setDetails, assistants }) {
	const handleInputChange = (key, value) => {
		setDetails({
			...details,
			[key]: value,
		});
	};

	return (
		<>
			<StepperHeading title="Conversation Type" subTitle="Select a conversation type for the campaign" />
			<Grid container spacing={2}>
				<Grid item xs={4}>
					<InputLabel>Model</InputLabel>
				</Grid>
				<Grid item xs={8}>
					<Select
						onChange={(e) => handleInputChange('assistantId', e)}
						placeholder="Select Model"
						style={{ width: '100%' }}
						getPopupContainer={(node) => node.parentNode}
						value={details?.assistantId}
						options={[{ name: 'Select', id: '' }, ...assistants].map((i) => {
							return { label: i?.name, value: i?.id };
						})}
					/>
				</Grid>
			</Grid>
		</>
	);
}
