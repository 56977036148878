import React, { useEffect } from 'react';
import { Alert, Grid, InputLabel } from '@mui/material';
import StepperHeading from '../StepperHeading';
import QuillField from '../../../../Components/QuillField';

const MESSAGE =
	'Note - Please do not include greetings such as "hello," "hi," or "dear," as they will be automatically fetched by the system.';

export default function CampaignEmail({ details, setDetails }) {
	const handleInputChange = (key, value) => {
		setDetails({
			...details,
			email: {
				...details?.email,
				[key]: value,
			},
		});
	};

	return (
		<>
			<StepperHeading title="Email" subTitle="Fill in the details for the email to be sent to the employees" />
			<Grid container spacing={2}>
				{/* <Grid item xs={4}>
					<InputLabel>Email Id</InputLabel>
				</Grid>
				<Grid item xs={8}>
					<TextField
						value={details?.email?.id}
						onChange={(e) => handleInputChange('id', e.target.value)}
						fullWidth
						size="small"
					/>
				</Grid> */}
				<Grid item xs={4}>
					<InputLabel>Email Body</InputLabel>
				</Grid>
				<Grid item xs={8} sx={{ '.ql-editor': { height: 100 } }}>
					<Alert sx={{ mb: 1 }} icon={false} color="warning">
						{MESSAGE}
					</Alert>
					<QuillField value={details?.email?.body} onChange={(e) => handleInputChange('body', e)} />
				</Grid>

				<Grid item xs={4}>
					<InputLabel>Email Signature</InputLabel>
				</Grid>
				<Grid item xs={8} sx={{ '.ql-editor': { height: 100 } }}>
					<QuillField value={details?.email?.signature} onChange={(e) => handleInputChange('signature', e)} />
				</Grid>

				{/* <Grid item xs={4}>
					<InputLabel>Calendar Link</InputLabel>
				</Grid>
				<Grid item xs={8}>
					<TextField
						value={details?.email?.link}
						onChange={(e) => handleInputChange('link', e.target.value)}
						fullWidth
						size="small"
					/>
				</Grid> */}

				{/* <Grid item xs={4}>
					<InputLabel>Duration</InputLabel>
				</Grid>
				<Grid item xs={8}>
					<Select
						onChange={(e) => handleInputChange('duration', e)}
						style={{ width: '50%' }}
						getPopupContainer={(node) => node.parentNode}
						defaultValue={details?.email?.duration}
						options={durations}
					/>
				</Grid> */}
			</Grid>
		</>
	);
}
