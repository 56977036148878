import { Card, Chip, Stack, Typography } from '@mui/material';
import React from 'react';
import { AppColors } from '../../../Utils/helper/AppColors';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';

export default function SentimentsCard(props) {
	const { label, value, change, impact, top } = props;
	const isPositive = impact === 'positive';
	return (
		<Card sx={{ gap: 0.5 }}>
			{top && (
				<Typography variant="subtitle2" fontWeight={500}>
					{label}
				</Typography>
			)}

			<Stack direction="row" alignItems="center" gap={1} width="100%">
				<Typography fontSize={26} fontWeight={500} color={AppColors.TEXT_PRIMARY}>
					{value}
				</Typography>
				<Typography fontSize={14} fontWeight={500} color={isPositive ? AppColors.TEXT_SUCCESS : AppColors.TEXT_ERROR}>
					{isPositive ? '+ve' : '-ve'}
				</Typography>

				{!top && (
					<Typography
						sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
						ml={2}
						flex={1}
						variant="subtitle2"
						fontWeight={500}
					>
						{label}
					</Typography>
				)}

				<Chip
					label={change}
					icon={isPositive ? <FaArrowUp size={10} /> : <FaArrowDown size={10} />}
					color={isPositive ? 'success' : 'error'}
					sx={{ ml: 'auto' }}
					size="small"
				/>
			</Stack>
		</Card>
	);
}
