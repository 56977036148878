import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { HiOutlineMail } from 'react-icons/hi';
import { AppColors } from '../../Utils/helper/AppColors';
import { LuArrowLeft } from 'react-icons/lu';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATH_NAME } from '../../Routes/Pathname';
import authBackground from '../../Assets/authBackground.svg';
import useResponsive from '../../Utils/hooks/useResponsive';

export default function VerificationLink() {
	const navigate = useNavigate();
	const location = useLocation();
	const { small } = useResponsive();

	return (
		<Stack
			sx={{ background: `url(${authBackground})`, backgroundPosition: 'center' }}
			width={small ? '80%' : 600}
			mx="auto"
			alignItems="center"
			gap={2}
			pt={8}
		>
			<Stack
				bgcolor={AppColors.WHITE}
				width={56}
				height={56}
				border="1px solid #EAECF0"
				borderRadius="12px"
				alignItems="center"
				justifyContent="center"
			>
				<HiOutlineMail color="#344054" size={26} />
			</Stack>

			<Typography textAlign="center" fontSize={30} fontWeight={600} color={AppColors.TEXT_PRIMARY}>
				Check your email
			</Typography>
			<Typography textAlign="center" fontSize={16} fontWeight={400} color={AppColors.TEXT_TERTIARY}>
				We sent a verification link to
				<br />
				<Typography fontWeight={500}>{location?.state?.email}</Typography>
			</Typography>

			<Button variant="text" color="secondary" onClick={() => navigate(PATH_NAME.LOGIN)} startIcon={<LuArrowLeft />}>
				Back to Login
			</Button>
		</Stack>
	);
}
