import { Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Checkbox, Tag } from 'antd';
import FilterSelectionContainer from './FilterSelectionContainer';

export default function FilterSelection({
	onClose,
	selectedView,
	setSelectedFilter,
	selectedFilter,
	setAdditionalFilters,
	filters,
}) {
	const [time, setTime] = useState([
		{
			item: 'quarterly',
			subItems: ['Q1', 'Q2', 'Q3', 'Q4'],
			selected: false,
		},
		{
			item: 'bi-annually',
			subItems: ['Q1-Q2', 'Q3-Q4'],
			selected: false,
		},
		{
			item: 'annually',
			subItems: ['2022', '2023', '2024'],
			selected: false,
		},
	]);

	const [selectedQuater, setSelectedQuater] = useState([]);
	const [selectedAdditional, setSelectedAdditional] = useState([]);

	const handleSelectTime = (i) => {
		setSelectedQuater([]);
		const temp = time.map((t) => {
			if (i.item == t.item) {
				return {
					...t,
					selected: !t.selected,
				};
			} else {
				return { ...t, selected: false };
			}
		});
		setTime(temp);
	};

	const handleSelectQuater = (i) => {
		if (!selectedQuater?.includes(i)) {
			setSelectedQuater([...selectedQuater, i]);
		} else {
			setSelectedQuater(selectedQuater.filter((s) => s !== i));
		}
	};

	const handleSelectAddition = (i) => {
		let exists = selectedAdditional.find((s) => s.code == i.code);
		if (!exists) {
			setSelectedAdditional([...selectedAdditional, i]);
		} else {
			setSelectedAdditional(selectedAdditional.filter((s) => s.code !== i.code));
		}
	};

	const handleApplyChanges = () => {
		const item = {
			type: time?.find((i) => i.selected)?.item?.toLowerCase(),
			values: selectedQuater,
		};
		setSelectedFilter(item);
		setAdditionalFilters(selectedAdditional);
		onClose();
	};

	useEffect(() => {
		if (selectedFilter?.type) {
			const temp = time.map((t) => {
				if (t.item == selectedFilter?.type) {
					return {
						...t,
						selected: true,
					};
				} else {
					return { ...t, selected: false };
				}
			});
			setSelectedQuater(selectedFilter?.values);
			setTime(temp);
		}
	}, [selectedFilter]);

	// filters
	const ADDITIONAL_FILTERS = {
		ORGANIZATIONAL: [],
		DEPARTMENTAL: filters?.departments?.map((i) => {
			return { code: i, color: 'purple', barColor: '#0A06F4' };
		}),
		HIERARCHICAL: filters?.roles?.map((i) => {
			return { code: i, color: 'blue', barColor: '#0A06F4' };
		}),
		LOCATION: filters?.locations?.map((i) => {
			return { code: i, color: 'blue', barColor: '#0A06F4' };
		}),
		GENDER: filters?.genders?.map((i) => {
			return { code: i, color: 'blue', barColor: '#0A06F4' };
		}),
	};

	return (
		<FilterSelectionContainer onClose={onClose} handleApplyChanges={handleApplyChanges}>
			<Typography variant="subtitle1">{selectedView?.LABEL}</Typography>

			<Stack gap={1.5} px={1}>
				{time?.map((i, index) => (
					<>
						<Stack key={index} direction="row" alignItems="center" gap={1}>
							<Checkbox checked={i.selected} onClick={() => handleSelectTime(i)} />
							<Typography textTransform="capitalize" variant="subtitle1">
								{i.item}
							</Typography>
						</Stack>

						{i.selected && (
							<Stack ml={2} gap={0.5}>
								{i?.subItems?.map((s, sIndex) => (
									<Stack key={sIndex} direction="row" alignItems="center" gap={1}>
										<Checkbox checked={selectedQuater.includes(s)} onClick={() => handleSelectQuater(s)} />
										<Typography variant="subtitle1">{s}</Typography>
									</Stack>
								))}
							</Stack>
						)}
					</>
				))}
			</Stack>

			<br />

			{ADDITIONAL_FILTERS[selectedView?.CODE]?.length > 0 && (
				<>
					<Typography variant="subtitle1">Additional Filters</Typography>
					<Stack gap={1.5} px={1}>
						{ADDITIONAL_FILTERS[selectedView?.CODE]?.map((i) => (
							<Stack direction="row" alignItems="center" gap={1}>
								<Checkbox checked={selectedAdditional.find((s) => s.code === i.code)} onClick={() => handleSelectAddition(i)} />
								<Tag color={i.color} style={{ textTransform: 'capitalize', borderRadius: 50 }}>
									{i.code.toLowerCase()}
								</Tag>
							</Stack>
						))}
					</Stack>
				</>
			)}
		</FilterSelectionContainer>
	);
}
