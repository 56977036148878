import { Stack } from '@mui/material';
import React from 'react';
import { AppColors } from '../Utils/helper/AppColors';

export default function IconContainer({ size = 48, icon }) {
	return (
		<Stack
			height={size}
			width={size}
			borderRadius="8px"
			alignItems="center"
			justifyContent="center"
			border="1px solid"
			borderColor={AppColors.BORDER_GREY}
			flexShrink={0}
			sx={{
				svg: {
					fontSize: 22,
					color: '#344054',
				},
			}}
		>
			{icon}
		</Stack>
	);
}
