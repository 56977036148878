import { Card, Chip, Grid, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageHeading from '../../Components/PageHeading';
import RiskCard from './Components.js/RiskCard';
import CultService from '../../Services/CultService';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { HelperTexts } from '../../Utils/helper/HelperTexts';
import PageLoader from '../../Components/PageLoader';
import { DotIcon } from '../../Components/Icons/Icons';
import { AppColors } from '../../Utils/helper/AppColors';

const RISK_TYPE = {
	employeeEngagement: {
		label: 'Employee Engagement',
	},
	productivityDrivers: {
		label: 'Productivity Drivers',
	},
	employeeWellness: {
		label: 'Employee Wellness',
	},
	culturalAlignment: {
		label: 'Cultural Alignment',
	},
	risk: {
		label: 'Risk',
	},
};

export default function CriticalRisks() {
	const [loading, setLoading] = useState(false);

	const company = useSelector((state) => state?.app?.company);
	const [risks, setRisks] = useState({});

	const [value, setValue] = React.useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const getDetails = async () => {
		setLoading(true);
		try {
			let res = await CultService.getCriticalRisks({ params: { companyId: company?._id } });
			setRisks(res?.data?.data);
		} catch (err) {
			toast.error(HelperTexts?.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (company?._id) getDetails();
	}, [company]);

	return (
		<Stack>
			{loading && <PageLoader />}
			<PageHeading title="Critical Risks" />

			<Stack mt={5} gap={2}>
				<ToggleButtonGroup size="small" color="primary" value={value} exclusive onChange={handleChange}>
					{Object.keys(risks).map((i, index) => (
						<ToggleButton sx={{ textTransform: 'none', px: 2, borderRadius: '8px', alignItems: 'center' }} value={index}>
							<Typography display="flex" alignItems="center" gap={1} variant="subtitle2" fontSize={13} fontWeight={500}>
								{value == index && <DotIcon color={AppColors.TEXT_SUCCESS} size={8} />} {RISK_TYPE[i]?.label}
							</Typography>
						</ToggleButton>
					))}
				</ToggleButtonGroup>

				<Grid container spacing={3}>
					{Object?.keys(risks)?.map(
						(risk, index) =>
							index == value && (
								<Grid key={index} item xs={12} md={12}>
									<Card sx={{ bgcolor: '#F9F5FF', gap: 2 }}>
										<Chip color="info" label={RISK_TYPE[risk]?.label} />
										<Grid container spacing={2}>
											{risks[risk]?.map((item, iIndex) => {
												return (
													<Grid key={iIndex} item xs={6}>
														<Card sx={{ gap: 3, height: '100%', justifyContent: 'space-between' }}>
															<Stack gap={1}>
																<Typography variant="subtitle1">{item?.impact_on_company}</Typography>
																<RiskCard item={item} />
															</Stack>
														</Card>
													</Grid>
												);
											})}
										</Grid>
									</Card>
								</Grid>
							),
					)}
				</Grid>
			</Stack>
		</Stack>
	);
}
