import { Grid, Modal, Stack } from '@mui/material';
import { Button, Result } from 'antd';
import React, { useState } from 'react';
import { AppColors } from '../../../Utils/helper/AppColors';
import useResponsive from '../../../Utils/hooks/useResponsive';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: AppColors.WHITE,
	borderRadius: 3,
};

export default function SuccessModal({ open, onClose }) {
	const { small } = useResponsive();

	const moods = [
		{
			label: 'Happy',
			name: 'grinning-face-with-big-eyes',
			code: 'happy',
		},
		{
			label: 'Sad',
			name: 'crying-face',
			code: 'sad',
		},
		{
			label: 'Goofy',
			name: 'winking-face-with-tongue',
			code: 'goofy',
		},
		{
			label: 'Angry',
			name: 'pouting-face',
			code: 'angry',
		},
		{
			label: 'Annoyed',
			name: 'expressionless-face',
			code: 'annoyed',
		},
		{
			label: 'Bored',
			name: 'unamused-face',
			code: 'bored',
		},
		{
			label: 'Cool',
			name: 'smiling-face-with-sunglasses',
			code: 'cool',
		},
		{
			label: 'Embarassed',
			name: 'flushed-face',
			code: 'embarassed',
		},
		{
			label: 'Sick',
			name: 'face-with-medical-mask',
			code: 'sick',
		},
		{
			label: 'Tired',
			name: 'sleeping-face',
			code: 'tired',
		},
	];

	const [selectedMood, setSelectedMood] = useState(false);

	return (
		<Modal open={open} onClose={onClose}>
			<Stack width={small ? '80%' : 'fit-content'} sx={style}>
				<Result
					status="success"
					title="Success"
					subTitle="You will receive a call shortly. Please submit your feedback after the call"
					extra={[
						<Grid container alignItems="center" justifyContent="space-between" spacing={1.5} mb={3}>
							{moods.map((mood) => (
								<Grid item xs={2.4} sm={2.4} md={1}>
									<Stack
										onClick={() => setSelectedMood(mood.code)}
										sx={{ cursor: 'pointer', opacity: selectedMood === mood.code ? 1 : 0.4 }}
									/>
								</Grid>
							))}
						</Grid>,

						<Button onClick={onClose} type="primary" key="console">
							Submit
						</Button>,
					]}
				/>
			</Stack>
		</Modal>
	);
}
