import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH_NAME } from '../../Routes/Pathname';
import { setAccessToken } from '../../Utils/storage/user';
import { Link, TextField, Typography } from '@mui/material';
import { AppColors } from '../../Utils/helper/AppColors';
import LabelledInput from '../../Components/LabelledInput';
import googleIcon from '../../Assets/Icons/google.svg';
import { FaApple } from 'react-icons/fa';
import LoginLayout from './Components/LoginLayout';
import CultService from '../../Services/CultService';
import toast from 'react-hot-toast';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { setCompany, setUser } from '../../store/reducers/appActions';
import { HelperTexts } from '../../Utils/helper/HelperTexts';
import { SIGN_UP_FLOW } from '../../Utils/helper/AppConstants';

export default function Login() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(false);

	const handleInputChange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

	const handleLogin = async () => {
		if (!data?.email || !data?.password) return;
		setLoading(true);
		try {
			let res = await CultService.login({ data });
			if (res.status == 200) {
				localStorage.setItem('userId', res?.data?.data?.id);
				setAccessToken(res?.data?.data?.token);
				navigate(PATH_NAME.DASHBOARD);
				let user = await CultService.getUserById({ params: { id: res?.data?.data?.id } });
				if (user.status == 200) {
					localStorage.setItem('user', JSON.stringify(user?.data?.data));
					dispatch(setUser(user?.data?.data));
					dispatch(setCompany(user?.data?.data?.company));
				}
			}
		} catch (err) {
			toast.error(err?.response?.data?.message || HelperTexts.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	return (
		<LoginLayout heading="Welcome Back" subHeading="Please enter your details.">
			<LabelledInput label="Email">
				<TextField name="email" onChange={handleInputChange} placeholder="Enter your email" fullWidth size="small" />
			</LabelledInput>
			<LabelledInput label="Password">
				<TextField
					name="password"
					onChange={handleInputChange}
					placeholder="Enter your password"
					type="password"
					fullWidth
					size="small"
				/>
			</LabelledInput>

			<Link onClick={() => toast.success(HelperTexts.CONTACT_US)} textAlign="right" fontWeight={600}>
				Forgot Password
			</Link>

			<Button loading={loading} type="primary" onClick={handleLogin}>
				Sign In
			</Button>

			{SIGN_UP_FLOW && (
				<>
					<Typography textAlign="center" fontSize={14} fontWeight={400} color={AppColors.TEXT_TERTIARY}>
						or
					</Typography>

					<Button icon={<img alt="google" width={16} src={googleIcon} />} type="default" color="secondary">
						Login with Google
					</Button>
					<Button icon={<FaApple size={18} />} style={{ backgroundColor: AppColors.BLACK }} type="primary">
						Login with Apple
					</Button>
				</>
			)}

			<Typography textAlign="center" fontWeight={500} fontSize={14} color={AppColors.TEXT_TERTIARY}>
				Don’t have an account? <Link onClick={() => toast.success(HelperTexts.CONTACT_US)}>Sign Up</Link>
			</Typography>
		</LoginLayout>
	);
}
