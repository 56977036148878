import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import useOnMount from '../Utils/hooks/useOnMount';
import { PATH_NAME } from '../Routes/Pathname';
import { getAccessToken } from '../Utils/storage/user';
import PageWrapper from '../Layout/PageWrapper';

export default function AuthGuard() {
	const navigate = useNavigate();

	const accessToken = getAccessToken();
	useOnMount(() => {
		if (!accessToken) {
			navigate(PATH_NAME.LOGIN);
		}
	});

	return (
		accessToken && (
			<PageWrapper>
				<Outlet />
			</PageWrapper>
		)
	);
}
