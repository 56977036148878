import { Chip, Stack, Typography } from '@mui/material';
import React from 'react';
import { AppColors } from '../../../Utils/helper/AppColors';

const RISK_LEVEL = {
	'very low': {
		label: 'Risk: Very Low',
		color: '#713F12',
		bgColor: '#FEF9C3',
	},
	low: {
		label: 'Risk: Low',
		color: '#713F12',
		bgColor: '#FEF9C3',
	},
	MIDHIGH: {
		label: 'Risk: Mid to High',
		color: '#EF4444',
		bgColor: '#F9DBAF',
	},
	moderate: {
		label: 'Risk: Moderate',
		color: '#EF4444',
		bgColor: '#F9DBAF',
	},
	high: {
		label: 'Risk: High',
		color: '#FFF',
		bgColor: '#EF4444',
	},
	'very high': {
		label: 'Risk: Very High',
		color: '#FFF',
		bgColor: '#B91C1C',
	},
};

export default function RiskCard({ item }) {
	return (
		<Stack gap={0.5} width="fit-content" p={1} borderRadius="4px" bgcolor="#F2F4F7">
			<Stack direction="row" alignItems="center" justifyContent="space-between" gap={0.5}>
				<Typography variant="subtitle1" color="#101828" fontSize={12}>
					{item?.issue}
				</Typography>
				<Chip
					sx={{
						fontSize: 10,
						fontWeight: 600,
						bgcolor: RISK_LEVEL[item?.impact_category]?.bgColor,
						color: RISK_LEVEL[item?.impact_category]?.color,
						border: '1px solid',
						borderColor: AppColors.BORDER_PRIMARY,
					}}
					size="small"
					label={RISK_LEVEL[item?.impact_category]?.label}
				/>
			</Stack>
			{item?.immediate && (
				<Typography ml={1} fontSize={10} fontWeight={500} color={AppColors.TEXT_RED}>
					*Immediate Focus
				</Typography>
			)}
		</Stack>
	);
}
