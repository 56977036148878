import React, { useEffect, useState } from 'react';
import StepperHeading from '../StepperHeading';
import {
	Button,
	Divider,
	Drawer,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { AppColors } from '../../../../Utils/helper/AppColors';
import { Checkbox, Tag } from 'antd';
import toast from 'react-hot-toast';
import { HelperTexts } from '../../../../Utils/helper/HelperTexts';
import CultService from '../../../../Services/CultService';
import UserThumbnail from '../../../../Components/UserThumbnail';
import DepartmentTag from '../../../../Components/DepartmentTag';
import { MdFilterList } from 'react-icons/md';
import FilterSelectionContainer from '../../../../Components/FilterSelectionContainer';
import { useSelector } from 'react-redux';
import SearchBar from '../../../../Components/SearchBar';
import { filterBySearch } from '../../../../Utils/helper/commonUtils';

const Filter = ({ setFilterQuery }) => {
	const [anchor, setAnchor] = useState(false);
	const company = useSelector((state) => state?.app?.company);
	const [filters, setFilters] = useState(null);
	const [selected, setSelected] = useState({});

	const handleSelectFilter = (category, value) => {
		setSelected((prevFilters) => {
			const categoryValues = prevFilters[category] || [];
			if (categoryValues.includes(value)) {
				const updatedCategoryValues = categoryValues.filter((item) => item !== value);
				if (updatedCategoryValues.length === 0) {
					const { [category]: removed, ...rest } = prevFilters;
					return rest;
				}

				return {
					...prevFilters,
					[category]: updatedCategoryValues,
				};
			} else {
				return {
					...prevFilters,
					[category]: [...categoryValues, value],
				};
			}
		});
	};

	const handleApplyFilters = () => {
		setFilterQuery(selected);
		setAnchor(false);
	};

	const getFilters = async () => {
		let res = await CultService.getFilterValues({ params: { companyId: company?._id } });
		setFilters(res?.data);
	};

	useEffect(() => {
		if (company?._id) getFilters();
	}, [company]);

	return (
		<Stack>
			<Stack direction="row" alignItems="center" gap={1}>
				<Button onClick={(e) => setAnchor(e.currentTarget)} variant="outlined" color="secondary" startIcon={<MdFilterList />}>
					Filters
				</Button>
			</Stack>

			<Drawer anchor="right" sx={{ zIndex: 9999 }} open={anchor} onClose={() => setAnchor(false)}>
				<FilterSelectionContainer title="users" onClose={() => setAnchor(false)} handleApplyChanges={handleApplyFilters}>
					<Stack gap={2} divider={<Divider />}>
						{Object?.keys(filters || {})?.map((filter, fIndex) => (
							<Stack gap={2} key={fIndex}>
								<Typography textTransform="capitalize" variant="subtitle1">
									{filter}
								</Typography>

								{filters?.[filter]?.map((item, iIndex) => (
									<Stack key={iIndex} direction="row" alignItems="center" gap={1}>
										<Checkbox
											checked={selected[filter]?.includes(item) || false}
											onChange={() => handleSelectFilter(filter, item)}
										/>
										<Tag style={{ borderRadius: 50, margin: 0, textTransform: 'capitalize' }} color="blue">
											{item}
										</Tag>
									</Stack>
								))}
							</Stack>
						))}
					</Stack>
				</FilterSelectionContainer>
			</Drawer>
		</Stack>
	);
};

export default function CampaignUsers({ details, setDetails }) {
	const [list, setList] = useState([]);

	const companyId = useSelector((state) => state?.app?.company)?._id;
	const [filterQuery, setFilterQuery] = useState({});
	const [search, setSearch] = useState('');

	const getList = async () => {
		try {
			let res = await CultService.getAllUsersPaginated({ params: { page: 1, companyId, filterQuery } });
			if (res.status == 200) {
				setList(res?.data?.users);
			}
		} catch (err) {
			toast.error(HelperTexts.SOMETHING_WENT_WRONG);
		} finally {
		}
	};

	const handleSelectUser = (user) => {
		let users = details?.users || [];
		if (!users.includes(user._id)) {
			users.push(user._id);
		} else {
			users = users.filter((i) => i !== user._id);
		}
		setDetails({ ...details, users });
	};

	const handleAllSelect = () => {
		if (details?.users.length === list.length) {
			setDetails({ ...details, users: [] });
		} else {
			setDetails({ ...details, users: list.map((i) => i._id) });
		}
	};

	useEffect(() => {
		if (companyId) getList();
	}, [filterQuery, companyId]);

	return (
		<>
			<StepperHeading
				renderRight={
					<Stack direction="row" alignItems="center" gap={2}>
						<SearchBar search={search} setSearch={setSearch} />
						<Filter filterQuery={filterQuery} setFilterQuery={setFilterQuery} />
					</Stack>
				}
				title="Users"
				subTitle="Select the users for the campaign"
			/>
			<TableContainer sx={{ border: 'none' }}>
				<Table stickyHeader>
					<TableHead
						sx={{
							'& .MuiTableCell-root': {
								fontSize: 12,
								color: AppColors.TEXT_TERTIARY,
								fontWeight: 500,
								paddingTop: 1,
								paddingBottom: 1,
							},
						}}
					>
						<TableCell>
							<Stack direction="row" alignItems="center" gap={1.5}>
								<Checkbox onClick={handleAllSelect} checked={details?.users?.length === list?.length} />
								Name
							</Stack>
						</TableCell>
						<TableCell>Email address</TableCell>
						<TableCell>Phone No.</TableCell>
						<TableCell>Teams</TableCell>
					</TableHead>
					<TableBody>
						{filterBySearch(list, search, ['firstName', 'lastName', 'email', 'phoneNumber'])?.map((c, index) => (
							<TableRow key={index}>
								<TableCell>
									<Stack direction="row" alignItems="center" gap={1.5}>
										<Checkbox
											checked={(details?.users || details?.compaignUser)?.includes(c._id)}
											onClick={() => handleSelectUser(c)}
											size="small"
										/>
										<UserThumbnail user={c} />
									</Stack>
								</TableCell>

								<TableCell>
									<Typography variant="subtitle2">{c?.email}</Typography>
								</TableCell>

								<TableCell>
									<Typography variant="subtitle2">{c?.phoneNumber}</Typography>
								</TableCell>
								<TableCell>{c?.department && <DepartmentTag department={c?.department} />}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}
