import { InputAdornment, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import LabelledInput from '../../Components/LabelledInput';
import { Button, Select } from 'antd';
import CultService from '../../Services/CultService';
import SuccessModal from './Components/SuccessModal';
import LoginLayout from '../Auth/Components/LoginLayout';
import axios from 'axios';

export default function FeedbackCall() {
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	const handleInputChange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

	const handleSubmit = async () => {
		setLoading(true);
		const payload = {
			assistantId: data?.model?.value,
			phoneNumberId: '0aa117d4-7d00-4ba0-9e9b-fa2e5be01c6d',
			customer: {
				number: '+91' + data?.phone,
				name: data?.name,
			},
		};
		const res = await CultService.createCall({ data: payload });
		if (res.status === 200) {
			setLoading(false);
			setData({});
			setSuccess(true);
		}
	};

	const models = [
		{ label: 'Workplace Safety - Manufacturing', value: 'dbd4fa05-3ee9-4e1e-b6c2-e4bf2ce56c91' },
		{ label: 'EXIT Interview', value: 'b5feca3a-9a6e-44f8-bcf4-3046c5bbded7' },
		{ label: 'Leadership & Management', value: 'a4c2d28f-6dcb-4049-985f-3182baeb6289' },
		{ label: 'HR Helpdesk', value: '9f5bfb53-ad68-4db3-bb29-a32ca1110205' },
		{ label: 'Employee Wellness', value: '548a83cc-9e0c-4e04-842b-a06693f57dae' },
		{ label: 'Generic', value: 'b639a417-d1e2-457c-88e9-9837a346938c' },
	];

	const isDisabled = () => {
		if (!data.name || !data?.phone || !data?.email || !data?.model) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<LoginLayout heading="Welcome" subHeading="Please fill in the form below">
			<Stack gap={2}>
				<LabelledInput label="Name">
					<TextField value={data.name || ''} name="name" onChange={handleInputChange} size="small" />
				</LabelledInput>
				<LabelledInput label="Phone">
					<TextField
						value={data.phone || ''}
						InputProps={{
							startAdornment: <InputAdornment position="start">+91</InputAdornment>,
						}}
						name="phone"
						onChange={handleInputChange}
						size="small"
					/>
				</LabelledInput>
				<LabelledInput label="Official Email Address">
					<TextField value={data.email || ''} name="email" onChange={handleInputChange} size="small" />
				</LabelledInput>
				<LabelledInput label="Model">
					<Select value={data?.model} onChange={(e, value) => setData({ ...data, model: value })} options={models} />
				</LabelledInput>
				<Button disabled={isDisabled()} loading={loading} onClick={handleSubmit} type="primary">
					Call Now
				</Button>
			</Stack>
			<SuccessModal open={success} onClose={() => setSuccess(false)} />
		</LoginLayout>
	);
}
