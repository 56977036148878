const initialState = {
	navBarExpanded: false,
	campaignDetails: {},
	user: {},
	company: null,
};

const appReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_USER':
			return {
				...state,
				user: action.payload,
			};
		case 'SET_NAVBAR':
			return {
				...state,
				navBarExpanded: action.payload,
			};

		case 'SET_CAMPAIGN':
			return {
				...state,
				campaignDetails: action.payload,
			};
		case 'SET_COMPANY':
			return {
				...state,
				company: action.payload,
			};

		default:
			return state;
	}
};

export default appReducer;
