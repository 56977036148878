import { Stack, Typography } from '@mui/material';
import React from 'react';
import { AppColors } from '../../../Utils/helper/AppColors';

export default function FooterCard({ icon, value, title, background }) {
	return (
		<Stack
			flex={1}
			gap={2}
			direction="row"
			alignItems="center"
			px={4}
			minHeight={100}
			maxHeight={100}
			borderRadius={3}
			sx={{ svg: { fontSize: 40, color: AppColors.WHITE }, background }}
		>
			{icon}
			<Typography fontSize={18} color="#fff" fontWeight={600}>
				{title || ''}
			</Typography>
			<Typography ml="auto" fontSize={30} color="#fff" fontWeight={600}>
				{value || 0}
			</Typography>
		</Stack>
	);
}
