import { ButtonBase, Rating, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { AppColors } from '../../../Utils/helper/AppColors';
import { LuArrowLeft, LuArrowRight } from 'react-icons/lu';
import { GoStarFill } from 'react-icons/go';

export default function ReviewCards() {
	const [reviews, setReviews] = useState([
		{
			image: 'https://www.denverheadshotco.com/wp-content/uploads/2023/06/Company-Headshots-scaled.jpg',
			message: '“CultureOS makes 1:1s with HR a breeze! I feel heard and engaged.”',
			name: 'Sharolyn Lee',
			company: 'Software Engineer, GreenTech Solutions',
			designation: 'Web Design Agency',
		},
		{
			image:
				'https://images.squarespace-cdn.com/content/v1/53b599ebe4b08a2784696956/1495768199675-YC0K3ZBS92FSN1SD49HR/professional-photographer-05.jpg',
			message: '“CultureOS streamlines the HR process”',
			name: 'David John',
			company: 'Software Engineer, OB Solutions',
			designation: 'Web Architect',
		},
		{
			image:
				'https://images.pexels.com/photos/1681010/pexels-photo-1681010.jpeg?cs=srgb&dl=pexels-creationhill-1681010.jpg&fm=jpg',
			message: '“CultureOS makes 1:1s with HR a breeze! I feel heard and engaged.”',
			name: 'Jade Wilson',
			company: 'Backend Engineer, GreenTech Solutions',
			designation: 'Software Solutions',
		},
	]);

	const [current, setCurrent] = useState(0);

	const handleNext = () => {
		if (Math.abs(current) == reviews.length - 1) {
			setCurrent(0);
		} else {
			setCurrent(current - 1);
		}
	};

	const handlePrev = () => {
		if (current == 0) {
			setCurrent(-(reviews.length - 1));
		} else {
			setCurrent(current + 1);
		}
	};

	return (
		<Stack height="100%" width="100%" direction="row" overflow="hidden" borderRadius="12px" position="relative">
			{reviews.map((i) => (
				<Stack
					sx={{ transform: `translateX(${current * 100}%)`, transition: '.3s' }}
					position="relative"
					height="100%"
					width="100%"
					flexShrink={0}
				>
					<img style={{ height: '100%', width: '100%', objectFit: 'cover' }} src={i.image} alt="review" />
					<Stack
						bgcolor="rgba(255, 255, 255, 0.2)"
						position="absolute"
						bottom={0}
						zIndex={2}
						left={0}
						p={2}
						width="100%"
						borderRadius="0 0 12px 12px"
					>
						<Typography mb={2.5} color={AppColors.WHITE} fontWeight={600} fontSize={30}>
							{i.message}
						</Typography>

						<Stack direction="row" justifyContent="space-between">
							<Stack>
								<Typography color={AppColors.WHITE} fontWeight={600} fontSize={30} mb={1}>
									{i.name}
								</Typography>
								<Typography color={AppColors.WHITE} fontWeight={500} fontSize={18}>
									{i.company}
								</Typography>
								<Typography color={AppColors.WHITE} fontWeight={400} fontSize={16}>
									{i.designation}
								</Typography>
							</Stack>

							<Rating
								sx={{ gap: 0.5, svg: { color: AppColors.WHITE } }}
								icon={<GoStarFill />}
								emptyIcon={<GoStarFill style={{ opacity: 0.1 }} />}
								size="small"
								value={4}
								readOnly
							/>
						</Stack>
					</Stack>
				</Stack>
			))}

			<Stack
				flexDirection="row"
				alignItems="center"
				gap={2}
				sx={{
					position: 'absolute',
					bottom: 20,
					right: 20,
					'& .MuiButtonBase-root': {
						width: 38,
						height: 38,
						border: '1px solid',
						borderColor: AppColors.WHITE,
						borderRadius: 50,
					},
				}}
			>
				<ButtonBase onClick={handlePrev}>
					<LuArrowLeft color={AppColors.WHITE} />
				</ButtonBase>
				<ButtonBase onClick={handleNext}>
					<LuArrowRight color={AppColors.WHITE} />
				</ButtonBase>
			</Stack>
		</Stack>
	);
}
