import { Chip, Dialog, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { CloseIcon } from '../../../Components/Icons/Icons';
import orgView from '../../../Assets/Icons/orgView.png';
import hierView from '../../../Assets/Icons/hierView.png';
import locationView from '../../../Assets/Icons/locationView.png';
import deptView from '../../../Assets/Icons/deptView.png';
import genderView from '../../../Assets/Icons/genderView.png';
import customView from '../../../Assets/Icons/customView.png';
import { AppColors } from '../../../Utils/helper/AppColors';
import { DASHBOARD_VIEWS } from '../../../Utils/helper/AppConstants';

export default function SelectViewModal({ open, onClose, selectedView, setSelectedView }) {
	const ViewCard = ({ title, subTitle, icon, comingSoon }) => (
		<Stack
			bgcolor={selectedView === title ? AppColors.BACKGROUND_ACTIVE : AppColors.WHITE}
			height={110}
			px={2}
			border="1px solid"
			borderColor={AppColors.BORDER_PRIMARY}
			direction="row"
			alignItems="center"
			gap={2}
			sx={{ transition: 'all .2s', cursor: 'pointer', ':hover': { bgcolor: AppColors.BACKGROUND_ACTIVE } }}
			onClick={() => {
				setSelectedView(title);
				onClose();
			}}
		>
			<img width={50} src={icon} alt="view icon" />
			<Stack gap={0.5}>
				<Typography sx={{ textTransform: 'capitalize' }} variant="subtitle1">
					{title.LABEL}
				</Typography>
				{comingSoon ? (
					<Chip
						size="small"
						label="Coming Soon"
						sx={{ bgcolor: AppColors.PRIMARY_LIGHT, color: AppColors.WHITE, fontWeight: 600, borderRadius: 1, p: 0 }}
					/>
				) : (
					<Typography variant="subtitle2">{subTitle}</Typography>
				)}
			</Stack>
		</Stack>
	);

	return (
		<Dialog type="blur" open={open} onClose={onClose}>
			<Stack p={2} gap={1}>
				<Stack justifyContent="flex-end" direction="row">
					<CloseIcon onClick={onClose} size={18} />
				</Stack>

				<Grid container spacing={2}>
					<Grid item xs={6}>
						<ViewCard icon={orgView} title={DASHBOARD_VIEWS.ORGANIZATIONAL} subTitle="Organizational view" />
					</Grid>
					<Grid item xs={6}>
						<ViewCard icon={hierView} title={DASHBOARD_VIEWS.HIERARCHICAL} subTitle="Hierarchical view" />
					</Grid>
					<Grid item xs={6}>
						<ViewCard icon={locationView} title={DASHBOARD_VIEWS.LOCATION} subTitle="Location view" />
					</Grid>
					<Grid item xs={6}>
						<ViewCard icon={deptView} title={DASHBOARD_VIEWS.DEPARTMENTAL} subTitle="Department view" />
					</Grid>
					<Grid item xs={6}>
						<ViewCard icon={genderView} title={DASHBOARD_VIEWS.GENDER} subTitle="Gender view" />
					</Grid>
					<Grid item xs={6}>
						<ViewCard icon={customView} title="Custom" comingSoon />
					</Grid>
				</Grid>
			</Stack>
		</Dialog>
	);
}
