import { Typography } from '@mui/material';
import React from 'react';
import { AppColors } from '../Utils/helper/AppColors';

export default function TextLogo(props) {
	const { size, color, align } = props;
	return (
		<Typography textAlign={align} fontSize={size || 30} fontWeight={600} color={color || AppColors.PRIMARY_DARK} {...props}>
			CultureOS
		</Typography>
	);
}
