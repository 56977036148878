// export const timeoutPromise = new Promise((resolve, reject) => {
// 	setTimeout(() => {
// 		reject(new Error('Request timed out achcccc'));
// 	}, 10000);
// });

export const randomIntArrayInRange = (min, max, n = 1) =>
	Array.from({ length: n }, () => Math.floor(Math.random() * (max - min + 1)) + min);

export const validateEmail = (email) => {
	let res = false;
	if (
		String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			)
	) {
		res = true;
	}
	return res;
};

export const filterBySearch = (items, term, keys) => {
	if (!term) return items;
	const getNestedValue = (obj, path) => {
		return path?.split('.')?.reduce((value, key) => {
			return value ? value[key] : undefined;
		}, obj);
	};
	return items?.filter((item) =>
		keys?.some((key) => {
			const value = getNestedValue(item, key);
			return value?.toString()?.toLowerCase()?.includes(term?.toLowerCase());
		}),
	);
};

export const convertToQueryParams = (filters) => {
	const singularKeyMap = {
		genders: 'gender',
		roles: 'role',
		timezones: 'timezone',
		departments: 'department',
	};

	const queryParams = Object.keys(filters)
		.map((key) => `${singularKeyMap[key] || key}=${filters[key].join(',')}`)
		.join('&');

	return queryParams;
};
