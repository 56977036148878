import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageHeading from '../../Components/PageHeading';
import { AppColors } from '../../Utils/helper/AppColors';
import MentionProgress from './Components/MentionProgress';
import DotChip from '../../Components/DotChip';
import CultService from '../../Services/CultService';
import toast from 'react-hot-toast';
import { HelperTexts } from '../../Utils/helper/HelperTexts';
import PageLoader from '../../Components/PageLoader';
import { useSelector } from 'react-redux';

const MOOD = {
	negative: { color: AppColors.TEXT_ERROR },
	positive: { color: AppColors.TEXT_SUCCESS },
	undefined: { color: '#667085' },
};

export default function ReportingContextInsights() {
	const [loading, setLoading] = useState(false);
	const [details, setDetails] = useState(null);
	const [contexts, setContexts] = useState([]);
	const company = useSelector((state) => state?.app?.company);

	const getDetails = async () => {
		setLoading(true);
		try {
			let res = await CultService.getContextInsigts({ params: { companyId: company?._id } });
			setDetails(res?.data?.data?.callContext);
		} catch (err) {
			toast.error(HelperTexts?.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (company) getDetails();
	}, [company]);

	useEffect(() => {
		setContexts(
			details?.flatMap((i) => i?.alignmentIssues?.map((item) => ({ ...item, mood: i.mood, clusterName: i.clusterName }))),
		);
	}, [details]);

	return (
		<Stack>
			{loading && <PageLoader />}
			<PageHeading title="Context Insights" subTitle="Track, manage and forecast your employee mindset" />

			<Stack mt={5}>
				<TableContainer sx={{ height: 'calc(100vh - 140px)' }}>
					<Table stickyHeader>
						<TableHead sx={{ '& .MuiTableCell-root': { fontSize: 12, color: AppColors.TEXT_TERTIARY, fontWeight: 500 } }}>
							<TableCell>Clusters</TableCell>
							<TableCell>Mentions</TableCell>
							<TableCell>Mood</TableCell>
						</TableHead>

						<TableBody>
							{contexts?.map((c) => (
								<TableRow>
									<TableCell>
										<Stack>
											<Typography variant="subtitle1">{c?.clusterName}</Typography>
											<Typography variant="subtitle2">{c?.name}</Typography>
										</Stack>
									</TableCell>
									<TableCell>
										<MentionProgress value={c?.percentage} />
									</TableCell>
									<TableCell>
										<DotChip label={c?.mood} color={MOOD[c?.mood]?.color} />
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Stack>
		</Stack>
	);
}
