import React from 'react';
import ReactQuill from 'react-quill';
import { AppColors } from '../Utils/helper/AppColors';
import 'react-quill/dist/quill.snow.css';

const formats = [
	'header',
	'font',
	'size',
	'bold',
	'italic',
	'underline',
	'strike',
	'blockquote',
	'list',
	'bullet',
	'indent',
	'link',
	'image',
	'video',
	'color',
];
const modules = {
	toolbar: [
		[{ size: [] }],
		['bold', 'italic', 'underline', 'strike', 'blockquote'],
		[{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
		['link'],
		['clean'],
		[{ color: Object.values(AppColors) }],
	],
};

export default function QuillField(props) {
	return <ReactQuill modules={modules} formats={formats} style={{ borderRadius: 20 }} {...props} />;
}
