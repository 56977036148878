import { Card, Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MonthChange from './MonthChange';
import { InfoIcon } from '../../../Components/Icons/Icons';
import CircularProgress from './CircularProgress';
import BarChart from '../../../Components/Charts/BarChart';
import _ from 'lodash';

export default function StatisticsCard({ title, value, monthChange, selectedView, selectedFilter, additionalFilters, tooltip }) {
	const [data, setData] = useState([]);
	const [labels, setLabels] = useState([]);

	useEffect(() => {
		if (additionalFilters?.length > 0 && _.isArray(value)) {
			setData(
				value?.map((i) => {
					return {
						label: i?.name,
						data: _.isArray(i?.value) ? i?.value?.map((j) => j?.value?.rate || j?.value) : [],
						backgroundColor: additionalFilters?.find((j) => j?.code == i?.name)?.barColor,
					};
				}),
			);
		} else {
			setData([
				{
					data: value?.length > 0 ? value?.map?.((i) => i.value?.rate || i?.value) : [],
					backgroundColor: selectedView?.COLOR,
				},
			]);
		}
	}, [additionalFilters, selectedFilter, value]);

	useEffect(() => {
		setLabels(
			additionalFilters?.length > 0
				? value?.[0]?.value?.map((i) => i.name)
				: selectedFilter?.values?.length > 0 && selectedFilter?.values,
		);
	}, [value, selectedFilter]);

	const chartData = {
		labels,
		datasets: data,
	};

	return (
		<Card sx={{ gap: 3, height: '100%', width: '100%', justifyContent: 'space-between' }}>
			<Stack direction="row" alignItems="center" gap={1}>
				<Typography>{title}</Typography>
				<Tooltip
					title={
						<Typography variant="subtitle1" fontSize={11}>
							{tooltip}
						</Typography>
					}
				>
					<Stack>
						<InfoIcon />
					</Stack>
				</Tooltip>
			</Stack>
			<Stack mx="auto">
				{selectedFilter?.length === 0 && (
					<CircularProgress color={selectedView.COLOR} value={_.isObject(value) ? value?.rate : value} />
				)}
				{selectedFilter?.values?.length > 0 && <BarChart grid data={chartData} width="100%" />}
			</Stack>
			{/* <MonthChange title="conversation" change={monthChange} effect="positive" /> */}
		</Card>
	);
}
