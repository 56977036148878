import axios from 'axios';
import { PATH_NAME } from '../Routes/Pathname';
import { clearSession, getAccessToken } from '../Utils/storage/user';

export const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_URL,
	// timeout: 60000,
	showLoader: false,
});

axiosInstance.interceptors.request.use(
	async (config) => {
		const accessToken = getAccessToken();
		if (accessToken) {
			config.headers.Authorization = `Bearer ${accessToken}`;
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error?.response?.status === 401) {
			console.log('401');
		}
		return Promise.reject(error);
	},
);
