import { Stack } from '@mui/material';
import React from 'react';
import { AppColors } from '../../../Utils/helper/AppColors';
import { GiCheckMark } from 'react-icons/gi';
import { GoDotFill } from 'react-icons/go';

const CustomStepComponent = (status) => {
	return (
		<Stack
			alignItems="center"
			justifyContent="center"
			width={24}
			bgcolor={status.completed || status?.active ? AppColors.PRIMARY_LIGHT : '#f8fbfb'}
			border="1.5px solid"
			borderColor={status.completed || status?.active ? AppColors.PRIMARY_LIGHT : '#EAECF0'}
			height={24}
			borderRadius={50}
		>
			{status?.completed && <GiCheckMark size={12} color={AppColors.WHITE} />}
			{status?.active && <GoDotFill size={14} color={AppColors.WHITE} />}
			{!status.completed && !status.active && <GoDotFill size={14} color="#D0D5DD" />}
		</Stack>
	);
};

export default CustomStepComponent;
