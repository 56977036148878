import { Chip } from '@mui/material';
import React from 'react';
import { GoDotFill } from 'react-icons/go';

export default function DotChip({ color, label, rounded }) {
	return (
		<Chip
			icon={<GoDotFill size={12} color={color} />}
			sx={{ borderRadius: rounded ? 20 : 1.5, textTransform: 'capitalize' }}
			size="small"
			variant="outlined"
			color="secondary"
			label={label}
		/>
	);
}
