import { Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomStepComponent from './CustomStepComponent';
import { AppColors } from '../../../Utils/helper/AppColors';
import CampaignUsers from './CampaignSteps/CampaignUsers';
import BasicDetails from './CampaignSteps/BasicDetails';
import Scheduling from './CampaignSteps/Scheduling';
import VoiceAndTone from './CampaignSteps/VoiceAndTone';
import CampaignEmail from './CampaignSteps/CampaignEmail';
import CampaignQuestions from './CampaignSteps/CampaignQuestions';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATH_NAME } from '../../../Routes/Pathname';
import CultService from '../../../Services/CultService';
import { Button } from 'antd';
import toast from 'react-hot-toast';
import { HelperTexts } from '../../../Utils/helper/HelperTexts';
import { useSelector } from 'react-redux';
import { CAMPAIGN_STATUS } from '../../../Utils/helper/AppConstants';
import CampaignConversationType from './CampaignSteps/CampaignConversationType';
import CampaignPreview from './CampaignSteps/CampaignPreview';

export default function CreateEditCampaign(props) {
	const { edit, setOpen = {}, fetch } = props;
	const navigate = useNavigate();
	const location = useLocation();
	const selected = location?.state?.campaign;

	const [loading, setLoading] = useState(false);
	const [assistants, setAssistants] = useState([]);

	const steps = [
		{ label: 'Basic Details', Component: BasicDetails },
		{ label: 'Scheduling', Component: Scheduling },
		// { label: 'Voice & tone',  Component: VoiceAndTone },
		{ label: 'Users', Component: CampaignUsers },
		{ label: 'Email', Component: CampaignEmail },
		// { label: 'Questions',  Component: CampaignQuestions },
		{ label: 'Conversation Type', Component: CampaignConversationType },
		{ label: 'Preview', Component: CampaignPreview },
	];

	const [activeIndex, setActiveIndex] = useState(0);

	const [details, setDetails] = useState({});
	const company = useSelector((state) => state?.app?.company);

	const getModels = async () => {
		try {
			let res = await CultService.getAllModelAssistanst({ params: { companyId: company?._id } });
			if (res.status == 200) {
				setAssistants(res?.data?.data);
			}
		} catch (err) {
			toast.error(HelperTexts.SOMETHING_WENT_WRONG);
		} finally {
		}
	};

	useEffect(() => {
		if (company?._id) getModels();
	}, [company]);

	const checkForErrors = () => {
		if (+details?.scheduling?.maxTryPerDay > +details?.scheduling?.maxTry) {
			throw new Error('Tries in a day cannot be greater than the max tries');
		}
	};

	const handleCreateCampaign = async (status = CAMPAIGN_STATUS.live.code) => {
		setLoading(true);
		try {
			checkForErrors();
			const res = await CultService.createCampaign({ data: { ...details, status, companyId: company?._id } });
			if (res.status == 200) {
				setOpen(false);
				fetch();
			}
		} catch (err) {
			toast.error(err?.message || HelperTexts.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	const handleUpdateCampaign = async (status = CAMPAIGN_STATUS.live.code) => {
		setLoading(true);
		try {
			checkForErrors();
			const res = await CultService.updateCampaign({
				params: { id: selected?._id },
				data: { ...details, status },
			});
			if (res.status == 200) {
				navigate(PATH_NAME.CAMPAIGN_LIST);
			}
		} catch (err) {
			toast.error(HelperTexts.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	const handleCancel = () => {
		if (edit) {
			navigate(PATH_NAME.CAMPAIGN_LIST);
		} else {
			setOpen(false);
		}
	};

	const handlePrev = () => {
		if (activeIndex > 0) {
			setActiveIndex(activeIndex - 1);
		} else handleCancel();
	};

	const handleNext = () => {
		if (activeIndex < steps.length - 1) {
			setActiveIndex(activeIndex + 1);
		} else {
			if (edit) {
				handleUpdateCampaign();
			} else {
				handleCreateCampaign();
			}
		}
	};

	const handleDraft = () => {
		if (edit) {
			handleUpdateCampaign(CAMPAIGN_STATUS.draft.code);
		} else {
			handleCreateCampaign(CAMPAIGN_STATUS.draft.code);
		}
	};

	useEffect(() => {
		if (selected) {
			let users = selected?.compaignUser?.map((i) => i?.userId?._id);
			setDetails({ ...selected, users });
		}
	}, [selected]);

	const isDisabled = () => {
		let res = false;
		if (activeIndex == steps.length - 1) {
			if (
				!details?.basicDetails?.name ||
				!details?.scheduling?.startDate ||
				!details?.scheduling?.endDate ||
				!details?.scheduling?.startTime ||
				!details?.scheduling?.maxTry ||
				!details?.scheduling?.endTime ||
				!details?.users ||
				details?.users?.length == 0 ||
				!details?.assistantId
			) {
				res = true;
			}
		}
		return res;
	};

	const isDisabledDraft = () => {
		let res = false;
		if (!details?.basicDetails?.name || details?.assistantId) {
			res = true;
		}
		return res;
	};

	return (
		<Stack height="100%" position="relative">
			<Stepper
				sx={{ mt: 2, '& .MuiStepConnector-line': { borderColor: '#EAECF0', borderWidth: 2 } }}
				activeStep={activeIndex}
				alternativeLabel
			>
				{steps.map((step, index) => (
					<Step key={step.label}>
						<StepLabel style={{ margin: 0, padding: 0 }} StepIconComponent={CustomStepComponent}>
							<Typography
								color={index <= activeIndex && AppColors.PRIMARY_LIGHT}
								variant="subtitle1"
								fontSize={12}
								fontWeight={600}
							>
								{step.label}
							</Typography>
						</StepLabel>
					</Step>
				))}
			</Stepper>

			<Stack px={edit ? 0 : 3} sx={{ maxHeight: 'calc(100vh - 200px)', overflow: 'scroll', pb: !edit && 11 }}>
				{steps?.map(({ Component, props }, index) => {
					return (
						activeIndex === index && (
							<Component key={index} {...props} details={details} setDetails={setDetails} assistants={assistants} />
						)
					);
				})}
			</Stack>

			<Stack
				p={edit ? 0 : 2}
				pt={2}
				borderTop={`1px solid ${AppColors.BORDER_GREY}`}
				direction="row"
				gap={2}
				sx={{
					position: 'absolute',
					width: '100%',
					left: 0,
					bottom: 0,
					bgcolor: AppColors.WHITE,
					borderRadius: '0 0 12px 12px',
				}}
			>
				<Button type="default" style={{ flex: 1 }} onClick={handlePrev}>
					{activeIndex == 0 ? 'Cancel' : 'Back'}
				</Button>

				<Button disabled={isDisabledDraft()} loading={loading} type="default" style={{ flex: 1 }} onClick={handleDraft}>
					Save as Draft
				</Button>

				<Button disabled={isDisabled()} loading={loading} type="primary" style={{ flex: 1 }} onClick={handleNext}>
					{activeIndex == steps.length - 1 ? 'Launch' : 'Next'}
				</Button>
			</Stack>
		</Stack>
	);
}
