import { Avatar, Dialog, Divider, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { AppColors } from '../Utils/helper/AppColors';
import UserThumbnail from './UserThumbnail';
import { Popover } from 'antd';

const MAX_USERS = 6;

export default function UsersList({ users }) {
	const [showAll, setShowAll] = useState(false);
	return (
		<Stack direction="row" alignItems="center">
			{users.slice(0, MAX_USERS).map((u) => {
				return (
					<Popover content={<UserThumbnail user={u} />}>
						<Stack ml={-1.5}>
							<UserThumbnail user={u} hideName />
						</Stack>
					</Popover>
				);
			})}
			{users?.length > MAX_USERS && (
				<Avatar
					onClick={() => setShowAll(!showAll)}
					sx={{
						ml: -1.5,
						height: 34,
						width: 34,
						fontSize: 14,
						bgcolor: '#F2F4F7',
						color: AppColors.TEXT_TERTIARY,
						borderWidth: 1,
						borderColor: AppColors.BORDER_GREY,
						borderStyle: 'solid',
					}}
				>
					{users?.slice(MAX_USERS).length}
				</Avatar>
			)}

			<Dialog PaperProps={{ sx: { borderRadius: 3 } }} open={showAll} onClose={() => setShowAll(false)}>
				<Stack p={2} width={350} gap={3}>
					<Typography variant="subtitle1" fontSize={16}>
						All Users
					</Typography>
					<Stack maxHeight="50vh" overflow="scroll" gap={1} divider={<Divider />}>
						{users?.map((u, index) => (
							<UserThumbnail user={u} key={index} />
						))}
					</Stack>
				</Stack>
			</Dialog>
		</Stack>
	);
}
