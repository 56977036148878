import React from 'react';
import CreateEditCampaign from './Components/CreateEditCampaign';
import PageHeading from '../../Components/PageHeading';
import { Stack } from '@mui/material';

export default function EditCampaign() {
	return (
		<Stack gap={2} flex={1}>
			<PageHeading title="Employee Wellness 1:1" />
			<CreateEditCampaign edit />
		</Stack>
	);
}
