import { Card, Stack, Typography } from '@mui/material';
import React from 'react';
import LineChart from '../../../Components/Charts/LineChart';
import { AppColors } from '../../../Utils/helper/AppColors';

export default function LineCard({ title }) {
	const chartData = {
		labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
		datasets: [
			{
				data: [2, 5, 2, 8, 3, 5, 10],
				borderColor: AppColors.CHART_BLUE,
				tension: 0,
				borderWidth: 1,
				pointRadius: 0,
			},
			{
				data: [4, 2, 10, 1, 6, 2, 20],
				borderColor: AppColors.CHART_GREEN,
				tension: 0,
				borderWidth: 1,
				pointRadius: 0,
			},
		],
	};
	return (
		<Card>
			<Stack gap={4} flex={1} width="100%">
				<Typography variant="subtitle1">{title}</Typography>
				<LineChart grid data={chartData} width="100%" />
			</Stack>
		</Card>
	);
}
