import {
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageHeading from '../../Components/PageHeading';
import { useNavigate, useParams } from 'react-router-dom';
import CultService from '../../Services/CultService';
import { PATH_NAME } from '../../Routes/Pathname';
import MentionProgress from '../Reporting/Components/MentionProgress';
import { AppColors } from '../../Utils/helper/AppColors';
import toast from 'react-hot-toast';
import { HelperTexts } from '../../Utils/helper/HelperTexts';
import PageLoader from '../../Components/PageLoader';
import CampaignDetailsReports from './Components/CampaignDetailsReports';

export default function CampaignDetails() {
	const { id } = useParams();
	const navigate = useNavigate();
	const [details, setDetails] = useState({});
	const [loading, setLoading] = useState(false);
	const [value, setValue] = React.useState(0);
	const handleChange = (event, newValue) => {
		if (newValue !== null) {
			setValue(newValue);
		}
	};
	const [items, setItems] = useState([]);

	const getDetails = async () => {
		setLoading(true);
		try {
			let res = await CultService.getCampaignById({ params: { id } });
			if (res.status == 200) {
				setDetails(res.data);
			}
		} catch (err) {
			toast.error(HelperTexts.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getDetails();
	}, []);

	const RenderIssues = () => (
		<Table stickyHeader>
			<TableHead sx={{ '& .MuiTableCell-root': { fontSize: 12, color: AppColors.TEXT_TERTIARY, fontWeight: 500 } }}>
				<TableCell>Clusters</TableCell>
				<TableCell>Mentions</TableCell>
				<TableCell>Issue</TableCell>
				<TableCell>Tentative Solution</TableCell>
			</TableHead>

			<TableBody>
				{items?.map((i, index) => (
					<TableRow key={index}>
						<TableCell>{i?.clusterName}</TableCell>
						<TableCell>
							<MentionProgress value={i?.percentage} />
						</TableCell>
						<TableCell>{i?.name}</TableCell>
						<TableCell>{i?.solution}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);

	const tabs = [
		{
			code: 'Issues',
			Component: RenderIssues,
		},
		{
			code: 'Reports',
			Component: CampaignDetailsReports,
			props: { details },
		},
	];

	function transformData(data) {
		return data?.flatMap((cluster) =>
			cluster?.alignmentIssues?.map((issue) => ({
				clusterName: cluster.clusterName,
				name: issue.name,
				solution: issue.solution,
				employeeReports: issue.employeeReports,
				percentage: issue.percentage,
				_id: issue._id,
			})),
		);
	}

	useEffect(() => {
		setItems(transformData(details?.insights));
	}, [details]);

	return (
		<Stack>
			<PageHeading
				onBackClick={() => navigate(PATH_NAME.CAMPAIGN_LIST)}
				title={details?.basicDetails?.name}
				subTitle={details?.basicDetails?.description}
			/>

			<Stack mt={5} gap={2}>
				<ToggleButtonGroup size="small" color="primary" value={value} exclusive onChange={handleChange}>
					{tabs?.map((tab, index) => (
						<ToggleButton
							key={index}
							sx={{ textTransform: 'none', px: 2, borderRadius: '8px', alignItems: 'center' }}
							value={index}
						>
							<Typography variant="subtitle2" fontSize={13} fontWeight={500}>
								{tab?.code}
							</Typography>
						</ToggleButton>
					))}
				</ToggleButtonGroup>

				{tabs.map(
					({ Component, props }, index) =>
						index == value && (
							<TableContainer sx={{ height: 'calc(100vh - 188px)' }} key={index}>
								<Component {...props} />
							</TableContainer>
						),
				)}
			</Stack>

			{loading && <PageLoader />}
		</Stack>
	);
}
