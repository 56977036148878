import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { RiDownloadCloud2Line } from 'react-icons/ri';
import PageHeading from '../../Components/PageHeading';
import { AppColors } from '../../Utils/helper/AppColors';
import SentimentsCard from './Components/SentimentsCard';

export default function ReportingSentiments() {
	const [topSentiments, setTopSentiments] = useState([
		{ label: 'Collaboration', value: '95%', change: '10%', impact: 'positive' },
		{ label: 'Company’s mission', value: '89%', change: '10%', impact: 'positive' },
		{ label: 'Career growth', value: '85%', change: '10%', impact: 'positive' },
		{ label: 'Workload', value: '76%', change: '2%', impact: 'negative' },
	]);

	const [otherSentiments, setOtherSentiments] = useState([
		{ label: 'Feeling undervalued', value: '65%', change: '10%', impact: 'negative' },
		{ label: 'Constructive feedback', value: '58%', change: '10%', impact: 'negative' },
		{ label: 'Job security', value: '47%', change: '2%', impact: 'negative' },
		{ label: 'Work culture', value: '40%', change: '10%', impact: 'positive' },
		{ label: 'Work-life balance', value: '37%', change: '2%', impact: 'negative' },
		{ label: 'Competitive pay', value: '28%', change: '10%', impact: 'positive' },
	]);

	const barData = [
		{
			name: 'Test name',
			value: 20,
			color: AppColors.CHART_PRIMARY_DARK,
		},
		{
			name: '',
			value: 39,
			color: AppColors.CHART_PRIMARY_LIGHT,
		},
		{
			name: '',
			value: 50,
			color: AppColors.CHART_PRIMARY_DARK,
		},
		{
			name: '',
			value: 17,
			color: AppColors.CHART_PRIMARY_LIGHT,
		},
		{
			name: '',
			value: 77,
			color: AppColors.CHART_PRIMARY_LIGHT,
		},
		{
			name: '',
			value: 38,
			color: AppColors.CHART_PRIMARY_DARK,
		},
		{
			name: '',
			value: 40,
			color: AppColors.CHART_PRIMARY_LIGHT,
		},
		{
			name: '',
			value: 78,
			color: AppColors.CHART_PRIMARY_LIGHT,
		},
		{
			name: '',
			value: 60,
			color: AppColors.CHART_PRIMARY_LIGHT,
		},
		{
			name: '',
			value: 50,
			color: AppColors.CHART_PRIMARY_DARK,
		},
	];

	return (
		<Stack>
			<PageHeading
				// renderRight={
				// 	<Button startIcon={<RiDownloadCloud2Line />} color="secondary" variant="outlined">
				// 		Export
				// 	</Button>
				// }
				title="Sentiments"
				subTitle="Track, manage and forecast your employee mindset"
			/>

			<Stack mt={5}>
				<PageHeading title="Top 5 Sentiments" titleSize={22} mb={2} />
				<Grid container columnSpacing={2}>
					{topSentiments.map((i) => (
						<Grid item xs={3}>
							<SentimentsCard top label={i.label} value={i.value} change={i.change} impact={i.impact} />
						</Grid>
					))}
				</Grid>
			</Stack>

			<Stack mt={5}>
				<Grid container columnSpacing={4}>
					<Grid item xs={5}>
						<PageHeading title="Other Sentiments" titleSize={18} mb={2} />
						<Stack gap={1}>
							{otherSentiments?.map((i, index) => (
								<SentimentsCard key={index} label={i.label} value={i.value} change={i.change} impact={i.impact} />
							))}
						</Stack>
					</Grid>

					<Grid item xs={7}>
						<Card sx={{ height: '100%', gap: 2, p: 3 }}>
							<PageHeading
								title="Sentiment  rate"
								subTitle="Track how your rating compares to your department average."
								titleSize={18}
								subTitleSize={14}
							/>

							<Stack direction="row" alignItems="center" gap={2} ml="auto">
								<Stack direction="row" alignItems="center" gap={1}>
									<Stack height={8} width={8} borderRadius={50} bgcolor={AppColors.CHART_PRIMARY_DARK} />
									<Typography variant="subtitle2">Positive</Typography>
								</Stack>
								<Stack direction="row" alignItems="center" gap={1}>
									<Stack height={8} width={8} borderRadius={50} bgcolor={AppColors.CHART_PRIMARY_LIGHT} />
									<Typography variant="subtitle2">Negative</Typography>
								</Stack>
							</Stack>

							<Stack
								gap={2}
								alignItems="flex-end"
								height="100%"
								justifyContent="space-between"
								direction="row"
								position="relative"
								width="100%"
							>
								<Stack
									sx={{
										'& .MuiBox-root': {
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											position: 'absolute',
											left: '0',
											width: '100%',
											gap: 1,
										},
										'& .MuiStack-root': {
											height: '1px',
											bgcolor: AppColors.BORDER_GREY,
											width: '100%',
										},
									}}
								>
									<Box style={{ bottom: -10 }}>
										<Typography variant="subtitle2">0</Typography>
										<Stack />
									</Box>
									<Box style={{ top: '73%' }}>
										<Typography variant="subtitle2">25</Typography>
										<Stack />
									</Box>
									<Box style={{ top: '50%' }}>
										<Typography variant="subtitle2">50</Typography>
										<Stack />
									</Box>
									<Box style={{ top: '25%' }}>
										<Typography variant="subtitle2">75</Typography>
										<Stack />
									</Box>
									<Box style={{ top: 0 }}>
										<Typography variant="subtitle2">100</Typography>
										<Stack />
									</Box>
								</Stack>

								{barData.map((i) => {
									return (
										<Stack borderRadius="5px 5px 0 0" position="relative" width={32} height={`${i.value}%`} bgcolor={i.color} />
									);
								})}
							</Stack>
						</Card>
					</Grid>
				</Grid>
			</Stack>
		</Stack>
	);
}
