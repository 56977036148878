import { IconButton, Stack } from '@mui/material';
import React from 'react';
import SideBarNav from './SideBarNav';
import { AppColors } from '../Utils/helper/AppColors';
import useResponsive from '../Utils/hooks/useResponsive';
import { LuMenu } from 'react-icons/lu';
import TextLogo from '../Components/TextLogo';
import { CloseIcon } from '../Components/Icons/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { setNavbarExpanded } from '../store/reducers/appActions';

const TOP_NAV_HEIGHT = '50px';

export default function PageWrapper({ children }) {
	const { small } = useResponsive();
	const dispatch = useDispatch();
	const navBarExpanded = useSelector((state) => state.app.navBarExpanded);

	return (
		<Stack paddingTop={small ? TOP_NAV_HEIGHT : 0} direction="row" sx={{ width: '100vw', height: '100vh' }}>
			<SideBarNav />
			<Stack
				sx={{
					width: small ? '100vw' : 'calc(100vw - 240px)',
					height: '100vh',
					overflow: 'auto',
					bgcolor: AppColors.WHITE,
					p: 2,
				}}
			>
				{children}
			</Stack>

			{small && (
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					px={2}
					sx={{ position: 'fixed', top: 0, left: 0, zIndex: 2 }}
					width="100%"
					height={TOP_NAV_HEIGHT}
					borderBottom="1px solid"
					borderColor={AppColors.BORDER_GREY}
					bgcolor={AppColors.WHITE}
				>
					<TextLogo size={18} />
					<IconButton size="small" onClick={() => dispatch(setNavbarExpanded(!navBarExpanded))}>
						{navBarExpanded ? (
							<CloseIcon size={26} color={AppColors.TEXT_PRIMARY} />
						) : (
							<LuMenu color={AppColors.TEXT_PRIMARY} size={26} />
						)}
					</IconButton>
				</Stack>
			)}
		</Stack>
	);
}
