import { IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { AppColors } from '../Utils/helper/AppColors';
import { LuArrowLeft } from 'react-icons/lu';

export default function PageHeading(props) {
	const { title, subTitle, titleSize = 26, subTitleSize = 14, renderRight, onBackClick } = props;
	return (
		<Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" alignItems="flex-start" {...props} gap={1}>
			<Stack direction="row" gap={1} alignItems="flex-start">
				{typeof onBackClick == 'function' && (
					<IconButton onClick={onBackClick} size="small">
						<LuArrowLeft size={26} color={AppColors.TEXT_PRIMARY} />
					</IconButton>
				)}

				<Stack mr="auto">
					<Typography fontSize={titleSize} color={AppColors.TEXT_PRIMARY} fontWeight={600}>
						{title}
					</Typography>
					{subTitle && (
						<Typography fontSize={subTitleSize} color={AppColors.TEXT_TERTIARY} fontWeight={400}>
							{subTitle}
						</Typography>
					)}
				</Stack>
			</Stack>

			{renderRight}
		</Stack>
	);
}
