import { Dialog, Stack, Typography } from '@mui/material';
import { Button } from 'antd';
import React from 'react';

export default function DeleteConfirm({ open, onClose, onDelete, loading, title, subTitle }) {
	return (
		<Dialog open={open} onClose={onClose}>
			<Stack p={2} minWidth={400}>
				<Typography variant="body1">{title}</Typography>
				<Typography variant="subtitle2">{subTitle}</Typography>

				<Stack mt={3} direction="row" gap={2} justifyContent="flex-end">
					<Button size="middle" onClick={onClose} type="default">
						Cancel
					</Button>
					<Button size="middle" loading={loading} onClick={onDelete} type="primary">
						Confirm
					</Button>
				</Stack>
			</Stack>
		</Dialog>
	);
}
