import { Stack, Typography } from '@mui/material';
import React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function CircularProgress({ value, color }) {
	return (
		<Stack position="relative">
			<Stack alignItems="center" justifyContent="center" bgcolor="#EEF0FA" width={200} height={200} borderRadius={50}>
				<Stack height={100} width={100} bgcolor="#fff" borderRadius={50} alignItems="center" justifyContent="center">
					<Typography variant="body1">{value || 0}%</Typography>
					<Stack width={170} height={170} sx={{ position: 'absolute' }}>
						<CircularProgressbar
							strokeWidth={12}
							styles={buildStyles({
								pathTransitionDuration: 0.5,
								pathColor: color,
								trailColor: '#d7d6f7',
								strokeLinecap: 'butt',
							})}
							value={value || 0}
						/>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
}
