import { useEffect, useRef } from 'react';

const useOnMount = (callback) => {
	let hasRun = useRef(false);
	useEffect(() => {
		if (!hasRun.current) {
			if (typeof callback === 'function') {
				callback();
			}
			hasRun.current = true;
		}
	});
};

export default useOnMount;
