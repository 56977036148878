import { Card, Divider, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Result } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { AppColors } from '../../../Utils/helper/AppColors';
import { FiCalendar } from 'react-icons/fi';
import moment from 'moment';
import { MdAccessTime } from 'react-icons/md';
import CultService from '../../../Services/CultService';
import toast from 'react-hot-toast';
import { HelperTexts } from '../../../Utils/helper/HelperTexts';
import LabelledInput from '../../../Components/LabelledInput';
import dayjs from 'dayjs';
import PageLoader from '../../../Components/PageLoader';

export default function MoodMarble() {
	const [loading, setLoading] = useState(false);
	const [fetching, setFetching] = useState(false);
	const [params] = useSearchParams();
	const [payload, setPayload] = useState({});

	const [details, setDetails] = useState({});

	const [startTime, setStartTime] = useState(null);
	const [endTime, setEndTime] = useState(null);

	const [success, setSuccess] = useState(false);

	const getDetails = async () => {
		setFetching(true);
		try {
			let res = await CultService.getCampaignUserDetails({ params: { id: params.get('id') } });
			if (res.status == 200) {
				setDetails(res.data?.data?.compaignId);
				setStartTime(dayjs(res.data?.data?.compaignId?.scheduling?.startTime, 'HH:mm'));
				setEndTime(dayjs(res.data?.data?.compaignId?.scheduling?.endTime, 'HH:mm'));
			}
		} catch (err) {
			toast.error(HelperTexts.SOMETHING_WENT_WRONG);
		} finally {
			setFetching(false);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			let res = await CultService.scheduleCampaign({ params: { id: params.get('id') }, data: payload });
			if (res?.data?.status) {
				setSuccess(true);
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			toast.error(HelperTexts.SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	const handleDateRange = (e) => {
		let startDate = moment(new Date(e)).format(moment.HTML5_FMT.DATE);
		setPayload({
			...payload,
			startDate,
		});
	};

	const handleInputChange = (key, value) => {
		setPayload({
			...payload,
			[key]: value,
		});
	};

	useEffect(() => {
		if (params.get('id')) {
			getDetails();
		}
	}, [params.get('id')]);

	const disabledHours = () => {
		if (!startTime || !endTime) return [];
		const hours = [];
		for (let i = 0; i < 24; i++) {
			if (i < startTime.hour() || i > endTime.hour()) {
				hours.push(i);
			}
		}
		return hours;
	};

	const disabledMinutes = (selectedHour) => {
		const minutes = [];
		if (!startTime || !endTime) return minutes;

		if (selectedHour === startTime.hour()) {
			for (let i = 0; i < startTime.minute(); i++) {
				minutes.push(i);
			}
		}

		if (selectedHour === endTime.hour()) {
			for (let i = endTime.minute() + 1; i < 60; i++) {
				minutes.push(i);
			}
		}

		return minutes;
	};

	return (
		<Stack
			sx={{ background: 'linear-gradient(to bottom, #2F1A6E, #774F8F)' }}
			alignItems="center"
			justifyContent="center"
			width="100vw"
			height="100vh"
			bgcolor="red"
		>
			{fetching && <PageLoader />}
			<Card sx={{ width: { xs: '90vw', md: 600 } }}>
				<Stack gap={2} width="100%">
					<Typography fontFamily="Quicksand" variant="h4" fontWeight={700} color={AppColors.TEXT_PRIMARY}>
						REMO
					</Typography>

					<Divider />

					{!success && (
						<>
							<Typography variant="body1" fontSize={16} fontWeight={500}>
								Schedule your call with REMO
							</Typography>

							<Typography variant="caption" fontSize={14} fontWeight={400}>
								Campaign - <span style={{ fontWeight: 500 }}>{details?.basicDetails?.name || 'NA'}</span>
							</Typography>

							<Divider />
						</>
					)}

					{!success && (
						<Stack direction={{ xs: 'column', sm: 'row' }} gap={4}>
							<LabelledInput label="Select Date">
								<DatePicker
									minDate={dayjs(
										moment(details?.scheduling?.startDate, 'YYYY-MM-DD')?.isAfter(moment().format())
											? details?.scheduling?.startDate
											: moment().format(),
									)}
									maxDate={dayjs(details?.scheduling?.endDate)}
									format="YYYY-MM-DD"
									onChange={handleDateRange}
									getPopupContainer={(node) => node.parentNode}
									suffixIcon={<FiCalendar color="#667085" size={18} />}
								/>
							</LabelledInput>

							<LabelledInput label="Select Time">
								<DatePicker
									needConfirm={false}
									minuteStep={15}
									showSecond={false}
									showNow={false}
									disabledHours={disabledHours}
									disabledMinutes={disabledMinutes}
									onChange={(e) => handleInputChange('startTime', moment(new Date(e)).format('HH:mm'))}
									suffixIcon={<MdAccessTime color="#667085" size={18} />}
									getPopupContainer={(node) => node.parentNode}
									picker="time"
									use12Hours
								/>
							</LabelledInput>
						</Stack>
					)}

					{success && (
						<Result
							status="success"
							title="Call scheduled Successfully"
							subTitle={`Your call for the campaign - ${details?.basicDetails?.name || 'NA'} has been scheduled.`}
						/>
					)}

					{!success && (
						<>
							<Divider />

							<Button loading={loading} style={{ marginLeft: 'auto' }} size="large" type="primary" onClick={handleSubmit}>
								Submit
							</Button>
						</>
					)}
				</Stack>
			</Card>
		</Stack>
	);
}
