import React from 'react';
import StepperHeading from '../StepperHeading';
import { Grid, InputLabel, Stack, TextField } from '@mui/material';
import { Checkbox, DatePicker } from 'antd';
import { FiCalendar } from 'react-icons/fi';
import { MdAccessTime } from 'react-icons/md';
import moment from 'moment';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

export default function Scheduling({ details, setDetails }) {
	const handleInputChange = (key, value) => {
		setDetails({
			...details,
			scheduling: {
				...details?.scheduling,
				[key]: value,
			},
		});
	};

	const handleDateRange = (e) => {
		let startDate = moment(new Date(e[0])).format(moment.HTML5_FMT.DATE);
		let endDate = moment(new Date(e[1])).format(moment.HTML5_FMT.DATE);
		setDetails({
			...details,
			scheduling: {
				...details?.scheduling,
				startDate,
				endDate,
			},
		});
	};

	return (
		<>
			<StepperHeading title="Scheduling" subTitle="Fill in the schedule of the campaign" />
			<Grid container spacing={2}>
				<Grid item xs={4}>
					<InputLabel>Start date - End date</InputLabel>
				</Grid>
				<Grid item xs={8}>
					<RangePicker
						minDate={dayjs()}
						defaultValue={[
							details?.scheduling?.startDate ? dayjs(details?.scheduling?.startDate, 'YYYY-MM-DD') : null,
							details?.scheduling?.endDate ? dayjs(details?.scheduling?.endDate, 'YYYY-MM-DD') : null,
						]}
						format="YYYY-MM-DD"
						onChange={handleDateRange}
						getPopupContainer={(node) => node.parentNode}
						style={{ width: '100%' }}
						suffixIcon={<FiCalendar color="#667085" size={18} />}
					/>
				</Grid>
				<Grid item xs={4}>
					<InputLabel>Time of call within a day</InputLabel>
				</Grid>
				<Grid item xs={8}>
					<Stack direction="row" gap={2}>
						<DatePicker
							needConfirm={false}
							minuteStep={15}
							showSecond={false}
							defaultValue={details?.scheduling?.startTime ? dayjs(details?.scheduling?.startTime, 'HH:mm') : null}
							onChange={(e) => handleInputChange('startTime', moment(new Date(e)).format('HH:mm'))}
							suffixIcon={<MdAccessTime color="#667085" size={18} />}
							getPopupContainer={(node) => node.parentNode}
							picker="time"
							placeholder="Start Time"
							use12Hours
						/>
						<DatePicker
							needConfirm={false}
							minuteStep={15}
							showSecond={false}
							defaultValue={details?.scheduling?.endTime ? dayjs(details?.scheduling?.endTime, 'HH:mm') : null}
							onChange={(e) => handleInputChange('endTime', moment(new Date(e)).format('HH:mm'))}
							suffixIcon={<MdAccessTime color="#667085" size={18} />}
							getPopupContainer={(node) => node.parentNode}
							picker="time"
							placeholder="End Time"
							use12Hours
						/>
					</Stack>
				</Grid>

				<Grid item xs={4}>
					<InputLabel>Maximum number of tries in campaign</InputLabel>
				</Grid>
				<Grid item xs={8}>
					<TextField
						value={details?.scheduling?.maxTry}
						onChange={(e) => handleInputChange('maxTry', e.target.value)}
						sx={{ width: 90 }}
						type="number"
						size="small"
					/>
				</Grid>

				<Grid item xs={4}>
					<InputLabel>Maximum number of tries in a day</InputLabel>
				</Grid>
				<Grid item xs={8}>
					<TextField
						value={details?.scheduling?.maxTryPerDay}
						onChange={(e) => handleInputChange('maxTryPerDay', e.target.value)}
						sx={{ width: 90 }}
						type="number"
						size="small"
					/>
				</Grid>

				<Grid item xs={4}>
					<InputLabel>Auto call before ending of campaign</InputLabel>
				</Grid>
				<Grid item xs={8}>
					<Checkbox
						checked={details?.defaultSchedule}
						onChange={(e) => setDetails({ ...details, defaultSchedule: e.target.checked })}
					/>
				</Grid>
			</Grid>
		</>
	);
}
