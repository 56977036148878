export const setNavbarExpanded = (data) => ({
	type: 'SET_NAVBAR',
	payload: data,
});
export const setCampaignDetails = (data) => ({
	type: 'SET_CAMPAIGN',
	payload: data,
});
export const setUser = (data) => ({
	type: 'SET_USER',
	payload: data,
});

export const setCompany = (data) => ({
	type: 'SET_COMPANY',
	payload: data,
});
