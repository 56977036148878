import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH_NAME } from '../../Routes/Pathname';
import { Button, Link, TextField, Typography } from '@mui/material';
import { AppColors } from '../../Utils/helper/AppColors';
import LabelledInput from '../../Components/LabelledInput';
import googleIcon from '../../Assets/Icons/google.svg';
import { FaApple } from 'react-icons/fa';
import LoginLayout from './Components/LoginLayout';
import { SIGN_UP_FLOW } from '../../Utils/helper/AppConstants';

export default function SignUp() {
	const navigate = useNavigate();

	const [data, setData] = useState({});

	const handleSignup = () => {
		navigate(PATH_NAME.VERIFICATION_LINK, { state: { email: data?.email } });
	};

	const handleInputChange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

	return (
		<LoginLayout heading="Sign Up" subHeading="Start your 30-day free trial.">
			<LabelledInput label="Email">
				<TextField name="email" onChange={handleInputChange} placeholder="Enter your email" fullWidth size="small" />
			</LabelledInput>

			<LabelledInput label="Password">
				<TextField
					helperText="Must be at least 8 characters."
					placeholder="Create a password"
					type="password"
					fullWidth
					size="small"
				/>
			</LabelledInput>

			<Button onClick={handleSignup} variant="contained">
				Get Started
			</Button>

			{SIGN_UP_FLOW && (
				<>
					<Typography textAlign="center" fontSize={14} fontWeight={400} color={AppColors.TEXT_TERTIARY}>
						or
					</Typography>

					<Button startIcon={<img alt="google" width={18} src={googleIcon} />} variant="outlined" color="secondary">
						Sign up with Google
					</Button>
					<Button startIcon={<FaApple />} color="secondary" variant="contained">
						Sign up with Apple
					</Button>
				</>
			)}

			<Typography textAlign="center" fontWeight={500} fontSize={14} color={AppColors.TEXT_TERTIARY}>
				Already have an account? <Link onClick={() => navigate(PATH_NAME.LOGIN)}>Login</Link>
			</Typography>
		</LoginLayout>
	);
}
