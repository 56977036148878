import { InputLabel, Stack } from '@mui/material';
import React from 'react';

export default function LabelledInput({ children, label, required, transparent, width }) {
	return (
		<Stack
			sx={{
				'& .MuiInputLabel-root': {
					mb: label ? 0.5 : 0,
				},
				'& .MuiTypography-root': {
					fontSize: 14,
					fontWeight: 400,
				},
				'& .MuiInputBase-root.Mui-disabled': {
					bgcolor: '#f5f5f5',
				},
			}}
			flex={1}
			width={width || '100%'}
		>
			<InputLabel>
				{label} {required && <span style={{ color: 'red' }}>*</span>}
			</InputLabel>
			{children}
		</Stack>
	);
}
