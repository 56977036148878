import { Stack, Typography } from '@mui/material';
import React from 'react';
import IconContainer from '../../Components/IconContainer';
import { Switch } from 'antd';

export default function IntegrationAppItem({ item }) {
	return (
		<Stack direction="row" alignItems="center" gap={2}>
			<IconContainer />
			<Stack>
				<Typography variant="subtitle1">{item?.name}</Typography>
				<Typography variant="subtitle2">{item?.description}</Typography>
			</Stack>
			<Stack flexShrink={0} direction="row" alignItems="center" gap={1} ml="auto">
				<Typography variant="subtitle1" fontSize={13}>
					Learn More
				</Typography>
				<Switch size="default" />
			</Stack>
		</Stack>
	);
}
